<template>
    <span
        :class="className"
    >
        <span class="o-cursor__dot"></span>
        <span class="o-cursor__circle"></span>
        <span class="o-cursor__arrows">
            <icon
                icon="arrow-simple-up"
                class="o-cursor__arrow -top"
            />
            <icon
                icon="arrow-simple-right"
                class="o-cursor__arrow -right"
            />
            <icon
                icon="arrow-simple-down"
                class="o-cursor__arrow -bottom"
            />
            <icon
                icon="arrow-simple-left"
                class="o-cursor__arrow -left"
            />
        </span>
        <span class="o-cursor__cross">
            <icon
                icon="cross"
            />
        </span>
        <anim-text
            v-if="label"
            :text="label"
            type="words"
            tag="span"
            :show="true"
            class="o-cursor__label | t-t5"
        />

        <span
            class="o-cursor__scroll"
            :class="{ 'is-visible' : showScroll }"
            :style="clipPath"
        >
        </span>

    </span>
</template>


<script>


import Icon from 'objects/Icon';
import AnimText from 'objects/AnimText';

import Variables from 'mixins/variables'

import { mapGetters } from 'vuex'
import { gsap } from 'gsap/all';
import { EventBus } from 'src/event-bus'

export default {
    name: 'AppCursor',
    components: {
        Icon,
        AnimText,
    },
    mixins: [ Variables ],
    data: () => ({
        isActive: false,
        state: [],
        isScrolling: false,
        scrollYPerc: 0,
    }),
    mounted() {

        this.docElement = document.documentElement

        // Document events
        document.addEventListener('mousemove', (e) => {
            this.move(e.clientX, e.clientY)
        })

        // Window events
        window.addEventListener('mousedown', () => {
            this.isActive = true
        })

        window.addEventListener('mouseup', () => {
            this.isActive = false
        })

        window.addEventListener('scroll', this.scroll)

        window.addEventListener('scrollstart', () => {
            this.isScrolling = true
            EventBus.$emit('cursor-reset')
        })

        window.addEventListener('scrollend', () => {
            this.isScrolling = false
        })

        // Hover
        EventBus.$on('cursor-hover-in', (params) => {

            this.state.push({
                type: params.type ? params.type : false,
                label: params.data && params.data.label ? params.data.label : false,
            })
        })

        EventBus.$on('cursor-hover-out', () => {
            this.state.pop()
        })

        // Set active
        EventBus.$on('cursor-set-active', (activate) => {

            this.isActive = activate
        })

        // Reset
        EventBus.$on('cursor-reset', () => {
            this.isActive = false

            this.state = []
        })

        // Update position
        EventBus.$on('cursor-update-position', ({x, y}) => {
            this.move(x, y)
        })
    },
    computed: {
        ...mapGetters('loader', {
            isLoading: 'isLoading',
        }),
        stateLength() {
            return this.state.length
        },
        type() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].type : false
        },
        label() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].label : false
        },
        className() {
            let className = 'o-cursor'

            if (this.type) {
                className += ` -${this.type}`
            }
            if (this.isActive) {
                className += ' is-active'
            }
            if (this.showScroll) {
                className += ' -hide'
            }

            return className
        },
        clipPath() {
            const progress = this.scrollYPerc

            let polygon = [
                [50, 50],
                [100, 0],
                [100, Math.min(4 * progress, 100)],
            ]

            let x, y
            if (progress >= 25) {
                x = Math.max((-4 * progress + 200), 0)
                y = 100
                polygon.push([x, y])
            }

            if (progress >= 50) {
                x = 0
                y = Math.max((-4 * progress + 300), 0)
                polygon.push([x, y])
            }

            if (progress >= 75) {
                x = Math.min((4 * progress - 300), 100)
                y = 0
                polygon.push([x, y])
            }

            polygon = polygon.map(p => p.join('% ').concat('%')).join(', ');

            return `--clip-path: polygon(${polygon});`
        },
        showScroll() {
            return this.isScrolling && this.state.length === 0
        },
    },
    methods: {
        move(x, y) {
            gsap.to(this.$el, {
                duration: .15,
                x,
                y,
                ease: 'sine.out',
            })
        },
        scroll() {
            this.scrollYPerc = Math.round(scrollY / (this.docElement.scrollHeight - this.W.h) * 100)
        }
    },
    watch: {
        isLoading(loading) {
            if (loading) {

            } else {

            }
        },
    },
}

</script>

<style lang="scss">

* {
    cursor: none !important;
}

.o-cursor {
    --cursor-color: #{$color-beige};

    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 6em;
    height: 6em;
    margin-top: calc(-6em/2);
    margin-left: calc(-6em/2);
    pointer-events: none;

    mix-blend-mode: exclusion;

    // City hover
    &.-city {
        mix-blend-mode: initial;

        .o-cursor__dot {
            background-color: $color-red;
            transform: scale(1);
            transition: transform .5s $in-out-circ, background-color .2s ease-in;
        }

        .o-cursor__circle {
            transform: scale(0);
            opacity: 0;
            transition: all .2s $in-circ;
        }
    }


    // Highlight
    &.-hover {

        .o-cursor__dot {
            background-color: transparent;
            border-width: .7em;
            //border-color: rgba($color-dark, .15);
            //border-color: var(--cursor-color);
            //transform: scale(.4);
            transform: scale(0);
            transition: transform .15s $in-circ, background-color .15s ease-in, border-width .15s ease-in;
        }

        .o-cursor__circle {
            transform: scale(.5);
            border-width: 1em;
            //opacity: 0;
            transition: all .3s $in-out-circ;
        }

        &.is-active {

            .o-cursor__circle {
                transform: scale(.7);
                transition: transform .1s ease-out;
            }
        }
    }

    // Drag
    &.-drag,
    &.-slide-x,
    &.-slide-y {

        .o-cursor__arrows {
            transform: scale(1) rotate(0);
        }

        .o-cursor__arrow {
            transition: opacity .2s ease-in;
        }

        .o-cursor__dot {
            transform: scale(.06);
        }

        .o-cursor__circle {
            transform: scale(0);
            opacity: 0;
            transition: all .15s $in-circ;
        }

        &.is-active {

            .o-cursor__arrow {
                &.-top       { transform: translate(0, -50%) }
                &.-bottom    { transform: translate(0, 50%) }
                &.-right     { transform: translate(50%, 0) }
                &.-left      { transform: translate(-50%, 0) }
            }

            .o-cursor__dot {
                transform: scale(.08);
            }
        }
    }

    // Drag
    &.-drag {

        .o-cursor__arrows {
            transition: transform .3s $in-out-circ, opacity .2s ease-in;
        }

        .o-cursor__arrow {
            opacity: 1;
            transform: none;
        }

        &.is-active {

            .o-cursor__arrows {
                transform: scale(1.1) rotate(-45deg);
            }
        }
    }

    // Slide
    &.-slide-x,
    &.-slide-y {

        .o-cursor__arrows {
            transition: none;
        }
    }

    // Slide X
    &.-slide-x {

        .o-cursor__arrows {
            transition: none;
        }

        .o-cursor__arrow {

            &.-left,
            &.-right {
                opacity: 1;
                transform: translate(0);
                transition: all .3s $in-out-circ
            }
        }

        &.is-active {

            .o-cursor__arrow {

                &.-left {
                    transform: translate(-50%, 0);
                }

                &.-right {
                    transform: translate(50%, 0);
                }
            }
        }
    }

    // Slide Y
    &.-slide-y {

        .o-cursor__arrow {

            &.-top,
            &.-bottom {
                opacity: 1;
                transform: translate(0);
                transition: all .3s $in-out-circ
            }
        }

        &.is-active {

            .o-cursor__arrow {

                &.-top {
                    transform: translate(0, -50%);
                }

                &.-bottom {
                    transform: translate(0, 50%);
                }
            }

        }
    }

    // Asset in grid canvas
    &.-canvas-item {
        mix-blend-mode: overlay;

        --cursor-color: #{$color-light};

        .o-cursor__dot {
            transform: scale(.35);
        }

        .o-cursor__circle {
            border-width: 2px;
            border-color: $color-dark;
            transform: scale(.5);
        }
    }

    // Close
    &.-close {
        mix-blend-mode: unset;

        .o-cursor__cross {
            opacity: 1;
            transform: scale(1);
            transition: all .3s $in-out-circ;
        }
    }

    &.-hide,
    &.-close {

        .o-cursor__dot {
            transform: scale(0);
            transition: all .2s $in-circ;
        }

        .o-cursor__dot,
        .o-cursor__circle {
            transform: scale(0);
            opacity: 0;
            transition: all .2s $in-circ;
        }
    }
}

.o-cursor__dot,
.o-cursor__circle,
.o-cursor__scroll {
    position: absolute;
    display: block;
    border-radius: 50%;
}

.o-cursor__dot {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--cursor-color);
    border: 0 solid transparent;
    transform: scale(.08);
    transition: transform .3s $out-circ, opacity .2s ease-out;
    will-change: transform;
}

.o-cursor__circle,
.o-cursor__scroll {
    top: calc((100% - 30%)/2);
    left: calc((100% - 30%)/2);
    width: 30%;
    height: 30%;
}

.o-cursor__circle {
    border: 1px solid var(--cursor-color);
    transition: all .3s $out-circ .1s;
}

.o-cursor__arrows {
    position: absolute;
    top: calc(50% - 35%/2);
    left: calc(50% - 35%/2);
    display: block;
    width: 35%;
    height: 35%;
    color: var(--cursor-color);
    transform: scale(.5) rotate(-90deg);
    transition: all .2s $in-circ;
    will-change: transform;
}

.o-cursor__cross {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $color-light;
    opacity: 0;
    transform: scale(0);
    transition: all .2s $in-circ;
}

.o-cursor__arrow {
    position: absolute;
    opacity: 0;
    transition: inherit;

    &.-top,
    &.-bottom {
        left: calc(50% - .5625em/2);
    }

    &.-left,
    &.-right {
        top: calc(50% - .5625em/2);
    }

    &.-top {
        top: 0;
    }

    &.-bottom {
        bottom: 0;
    }

    &.-right {
        right: 0;
        transform: translate(-200%);
    }

    &.-left {
        left: 0;
        transform: translate(200%);
    }
}

.o-cursor__label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $color-light !important;
}

.o-cursor__scroll {
    display: block;
    border: .25em solid $color-beige;
    border-radius: 50%;
    clip-path: var(--clip-path);
    opacity: 0;
    transform: rotate(-45deg) scale(.5);
    transition: opacity .2s ease-in, transform .2s $in-circ;

    @supports (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
        clip-path: var(--clip-path);
    }

    @supports not (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
        display: none;
    }

    &.is-visible {
        opacity: .7;
        transform: rotate(-45deg) scale(1);
        transition: opacity .2s ease-out, transform .2s $out-circ;
    }
}

</style>
