import { photosVolume } from './config';

/*
 * Craft GraphQL Queries
 */

// Default data
const defaultData = `
title
id
`;

// Seo
const seo = `
seo {
    title
    description
}
`

// Default data
const heading = `
heading
intro
description
`;


/** Globals **/

// Main navigation
export const nav = `{
    globalSet(handle: "nav") {
        ... on nav_GlobalSet {
            navNodes {
                title
                sectionHandle
            }
        }
    }
    assets(limit: 16, orderBy: "RAND()", active: true) {
        ${defaultData}
        width
        height
        ...on ${photosVolume}_Asset {
            awsTransformPath
            reference
            cleanTitle
        }
    }
}`;

// Missing informations
export const missingInfos = `{
    globalSet(handle: "missingInfos") {
        ... on missingInfos_GlobalSet {
            intro
            email
        }
    }
}`;


/** Entries **/

// Homepage
export const home = `{
    entry(section: "home") {
        ${defaultData}
        sectionHandle
        ...on home_home_Entry {
            heading
            intro
            ${seo}
            selectedPhotos {
                ${defaultData}
                width
                height
                ...on ${photosVolume}_Asset {
                    awsTransformPath
                    reference
                    cleanTitle
                }
            }
        }
    }
}`;

// Fonds
export const funds = `{
    entry(section: "funds") {
        ${defaultData}
        sectionHandle
        ...on funds_funds_Entry {
            ${heading}
            ${seo}
        }
    }
}`;

// Directory
export const directory = `{
    entry(section: "directory") {
        ${defaultData}
        sectionHandle
        ...on directory_directory_Entry {
            ${heading}
            ${seo}
        }
    }
}`;

// About
export const about = `{
    entry(section: "about") {
        ${defaultData}
        sectionHandle
        ...on about_about_Entry {
            ${heading}
            museumRedactor
            ${seo}
            museum {
                ...on museum_BlockType {
                    heading
                    desc
                    website {
                        label
                        url
                    }
                }
            }
        }
    }
}`;


// Category
export const categoryGroup = (group, args) => `{
    categories(group: "${group}", ${args}) {
        ${defaultData}
        groupHandle
    }
}`;

export const category = (id) => `{
    category(id: ${id}) {
        slug
        groupHandle
        ...on regions_Category {
            ${heading}
        }
        ...on dynamics_Category {
            ${heading}
            tags {
               ${defaultData}
               slug
            }
            categoryRegion {
                ${defaultData}
                slug
            }
            categoryFund {
                ${defaultData}
                slug
            }
        }
    }
    tag(id: ${id}) {
        slug
        groupHandle
    }
    asset(relatedTo: ${id}, active: true) {
        ${defaultData}
        width
        height
        ...on ${photosVolume}_Asset {
            awsTransformPath
            reference
            cleanTitle
        }
    }
}`;


// Tag
export const tags = (group, args) => `{
    tags(group: "${group}", ${args}) {
        ${defaultData}
        groupHandle
    }
}`;


// Photo
export const photo = (reference) => `{
    asset(title: "${reference}", volume: "${photosVolume}", active: true) {
        ${defaultData}
        width
        height
        ...on ${photosVolume}_Asset {
            reference
            date
            dateEnd
            photoWidth
            photoHeight
            awsTransformPath
            cleanTitle
            tags {
                ${defaultData}
                slug
            }
            categoryFund {
                ${defaultData}
                slug
            }
            categoryRegion {
                ${defaultData}
                slug
            }
        }
    }
}`;


// Search photos
export const photos = (args) => `{
    assets(${args}, volume: "${photosVolume}", active: true) {
        ${defaultData}
        width
        height
        ...on ${photosVolume}_Asset {
            reference
            date
            dateEnd
            photoWidth
            photoHeight
            awsTransformPath
            cleanTitle
            tags {
                ${defaultData}
                slug
            }
            categoryFund {
                ${defaultData}
                slug
            }
            categoryRegion {
                ${defaultData}
                slug
            }
        }
    }
}`;

export const photosBasic = (args) => `{
    assets(${args}, volume: "${photosVolume}", active: true) {
        ${defaultData}
        width
        height
        ...on ${photosVolume}_Asset {
            awsTransformPath
            reference
            cleanTitle
            date
            dateEnd
            categoryFund {
                ${defaultData}
                slug
            }
        }
    }
}`;

// Search photo count
export const searchCount = (args) => `{
    assetCount(${args}, volume: "${photosVolume}", active: true)
}`;

export default {
    nav,
    missingInfos,
    home,
    funds,
    directory,
    about,
    category,
    categoryGroup,
    tags,
    photo,
    photos,
    photosBasic,
    searchCount,
};
