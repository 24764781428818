<template>
    <div
        v-if="page && page.seo"
        class="p-funds | l-content"
    >

        <heading
            :heading="page.heading"
            :intro="page.intro"
            :description="page.description"
        />


        <div class="grid">
            <slider
                v-if='funds && funds.length > 0'
                :slides="funds"
                ref="sliderFunds"
            >
                <template #slide="item">
                    <card
                        :count="item.assetCount"
                        :date="item.heading"
                        :title="item.title"
                        :asset="item.asset"
                        :url="`/collection/${item.id}/${item.slug}`"
                        @assetLoaded="$refs.sliderFunds.resize()"
                    />
                </template>
            </slider>
        </div>
    </div>
</template>

<script>

import Card from 'objects/Card';
import Slider from 'components/Slider';
import Heading from 'layout/Heading';

import { mapState } from 'vuex'

export default {
    name: 'Funds',
    components: {
        Card,
        Slider,
        Heading,
    },
    props: {
        page: {},
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    computed: {
        ...mapState({
            funds: state => state.categories.funds
        }),
    },
};

</script>

<style lang="scss">

// .p-funds {}

</style>
