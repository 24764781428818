<template>
    <div
        v-if="page"
        class="p-collection | l-content"
    >

        <heading
            :key="page.id"
            :title="page.title"
            :heading="page.heading"
            :intro="page.intro"
            :description="page.description"
            :assets="page.asset"
            :previous="false"
        />

        <div
            v-if="page.groupHandle != 'dynamics'"
            class="grid"
        >
            <btn
                class="p-collection__filter"
                tag="router-link"
                :href="filterUrl"
                size="sm"
                label="Filtrer"
                v-view.reveal
            />
        </div>

        <grid-assets :relatedTo="relations" />

    </div>
</template>

<script>

import BtnReturn from 'objects/BtnReturn';
import GridAssets from 'objects/GridAssets';
import Btn from 'components/Btn';
import Heading from 'layout/Heading';

export default {
    name: 'Collection',
    components: {
        BtnReturn,
        GridAssets,
        Btn,
        Heading,
    },
    props: {
        page: {},
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : `${this.page.title} - Cyberphotos`
        }
    },
    computed: {
        filterUrl() {
            const page = this.page,
                  param = page.groupHandle || 'funds',
                  value = page.groupHandle == 'tags' ? page.title : page.id

            return `/recherche?${param}=${value}`
        },
        relations() {

            if(this.page.groupHandle === 'dynamics') {
                let relatedToTags = '', relatedToRegions = '', relatedToFunds = '', relatedToCategories = ''

                if (this.page.tags.length) {
                    //relatedToTags = `relatedToTags: [{group: "tags", id: ["and", ${this.page.tags.map(t => Number(t.id))}]}], `
                    relatedToTags = `relatedToTags: [{group: "tags", id: [${this.page.tags.map(t => Number(t.id))}]}], `
                }

                if (this.page.categoryRegion.length || this.page.categoryFund.length) {
                    if (this.page.categoryRegion.length) {
                        const _relatedToRegions = this.page.categoryRegion.map(c => Number(c.id))
                        relatedToRegions = `{group: "regions", id: [${_relatedToRegions}]}, `
                    }

                    if (this.page.categoryFund.length) {
                        const _relatedToFunds = this.page.categoryFund.map(c => Number(c.id))
                        relatedToFunds = `{group: "funds", id: [${_relatedToFunds}]}`
                    }

                    relatedToCategories = `relatedToCategories: [${relatedToRegions} ${relatedToFunds}]`
                }

                return `${relatedToTags} ${relatedToCategories}`

            } else {
                return `relatedTo: [${this.page.id}]`
            }
        },
    },
};

</script>

<style lang="scss">

// .p-collection {}

.p-collection__filter {
    --reveal-end: .6;

    margin-bottom: 1em;

    &.js-reveal {
        transform: translate(0, .5em);
        overflow: hidden;

        &:after {
            transform: scale(0, 1);
            transform-origin: 0 50%;
        }

        .c-btn__label {
            transform: translate(0, 100%);
        }

        &.is-visible {
            transform: translate(0);
            transition: transform .6s $in-out-circ;

            &:after {
                transform: scale(1);
                transition: transform .3s $in-out-circ .3s;
            }

            .c-btn__label {
                transform: translate(0);
                transition: transform .6s $in-out-circ;
            }
        }
    }
}

</style>
