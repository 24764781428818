<template>
    <i :class="className">
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="path"></use>
        </svg>
    </i>
</template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '',
        },
    },
    computed: {
        path() {
            return `/dist/svg/sprite.svg#svg-${this.icon}`;
        },
        className() {
            let className = `o-icon -${this.icon}`
            className = this.size ? `${className} -${this.size}`: className
            return className;
        }
    },
};

</script>

<style lang="scss">

.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        fill: currentColor;
    }

    &.-xs {
        font-size: .5rem;
    }
}

/*=================================
=            SVG Sizes            =
=================================*/

.svg-arrow-left,
.svg-arrow-right {
    width: 1em;
    height: .5625em;
}

.svg-arrow-up,
.svg-arrow-down {
    width: .5625em;
    height: 1em;
}

.svg-arrow-simple-up,
.svg-arrow-simple-down {
    width: .5625em;
    height: .3125em;
}

.svg-arrow-simple-left,
.svg-arrow-simple-right {
    width: .3125em;
    height: .5625em;
}

.svg-cross,
.svg-arrow-reduce,
.svg-arrow-expand {
    width: 1.2em;
    height: 1.2em;
}

.svg-search {
    width: 1.25em;
    height: 1.25em;
}


// Logos
.svg-mbsl {
    width: 10em;
    height: 2.78em;
}

.svg-mbsl-icon {
    width: 2em;
    height: 2em;
}

.svg-mrc-rdl {
    --svg-width: 5.2em;
    width: var(--svg-width);
    height: calc(165/280 * var(--svg-width));
}

.svg-hydroquebec {
    width: 8em;
    height: 2.54em;
}

.svg-mambo {
    width: 5.5em;
    height: 1.98em;
}

</style>
