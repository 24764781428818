<template>
    <div
        :class="className"
        v-cursor
    >
        <div class="o-select__inner">
            <span
                class="o-select__box"
                @click.stop="isFocused = !isFocused"
            >
                <span class="o-select__placeholder">
                    {{ option ? option[oKey] : placeholder}}
                </span>

                <span
                    v-if="value"
                    class="o-select__delete"
                    @click.stop="updateOption(false)"
                >
                    <icon
                        icon="cross"
                        size="xs"
                    />
                </span>
                <span class="o-select__arrow">
                    <icon icon="arrow-simple-down" />
                </span>
            </span>
            <ul class="o-select__list">
                <li
                    v-if="all"
                    class="o-select__option"
                >
                    <button
                        type="button"
                        class="o-select__text"
                        @click="reset()"
                    >
                        Tous
                    </button>

                </li>
                <li
                    v-for="(o, i) in options"
                    :key="`option-${i}`"
                    class="o-select__option"
                >
                    <button
                        type="button"
                        class="o-select__text"
                        :class="{ 'is-active' : activeIndex === i}"
                        @click="updateOption(o, i)"
                    >
                        {{ o[oKey] }}

                        <icon
                            v-if="activeIndex === i"
                            icon="cross"
                            size="xs"
                        />
                    </button>
                </li>
            </ul>
        </div>
        <input type="text" :value="value" hidden>
    </div>
</template>

<script>


import Icon from 'objects/Icon';

export default {
    name: 'AppInput',
    components: {
        Icon,
    },
    props: {
        placeholder: {
            type: String,
            default: 'Placeholder',
        },
        options: {
            type: Array,
            default: () => ({}),
        },
        as: String,
        all: {
            type: Boolean,
            default: false,
        },
        defaultValue: false,
    },
    data: () => ({
        value: '',
        option: false,
        isFocused: false,
        isFilled: false,
        activeIndex: -1,
    }),
    created() {
        window.addEventListener('click', this.onClick = () => {
            this.isFocused = false
        });

        if(this.defaultValue !== null) {
            this.setOption(this.defaultValue)
        }
    },
    computed: {
        className() {
            let className = 'o-select'

            if (this.disabled) {
                className += ' is-disabled'
            }
            if (this.isFilled) {
                className += ' is-filled'
            }
            if (this.isFocused) {
                className += ' is-focused'
            }

            return className
        },
        asKeyValue() {
            return this.as.split(':')
        },
        oKey() {
            return this.asKeyValue[0]
        },
        oValue() {
            return this.asKeyValue[1]
        },
    },
    methods: {
        updateOption(option, index=-1) {
            if (this.activeIndex === index) {
                this.option = false
                this.activeIndex = -1
            } else {
                this.option = option
                this.activeIndex = index
            }
        },
        setOption(value) {

            // Find option index
            const optionIndex = this.options.findIndex(option => option[this.oValue] == value)

            // Update option with value if exists
            if (optionIndex > -1) {
                this.updateOption(this.options[optionIndex], optionIndex)
            }
        },
        reset() {
            this.updateOption(false, this.activeIndex)
        }
    },
    watch: {
        option(option) {
            this.value = option === false ? '' : option[this.oValue]
            this.isFilled = this.value !== ''
            this.isFocused = false
            this.$emit('input', this.value)
        },
    },
    beforeDestroy() {
        window.removeEventListener('click', this.onClick)
    },
};

</script>

<style lang="scss">

.o-select {
    --c-bg: #{$color-light};
    --c-text: #{$color-dark};
    --c-border: #{$color-dark};

    --border: 1px solid var(--c-border);

    color: $color-dark;

    &.is-focused {
        z-index: 1;

        .o-select__arrow {
            transform: rotate(180deg);
        }

        .o-select__list {
            z-index: 10;
            display: block;
        }
    }
}

.o-select__inner {
    border: var(--border);
}

.o-select__box {
    display: flex;
    align-items: center;
    padding: calc(1em - 1px) 3.125em calc(1em - 1px) 1em;
    color: var(--c-text);
    background-color: var(--c-bg);
    user-select: none;
    cursor: pointer;
}

.o-select__placeholder {
    display: block;
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.o-select__delete {
    display: flex;
    justify-content: flex-end;
    height: 100%;
}

.o-select__arrow {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.125em;
    height: 100%;
    cursor: pointer;
    transition: transform .3s ease-out;
}

.o-select__list {
    position: absolute;
    top: 100%;
    right: -1px;
    left: -1px;
    width: auto;
    max-height: 12em;
    @include reset-list;
    background-color: var(--c-bg);
    border-right: var(--border);
    border-bottom: var(--border);
    border-left: var(--border);
    overflow-y: auto;

    display: none;
}

.o-select__option {
}

.o-select__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .75em 1em;
    text-align: left;
    cursor: pointer;
    transition: background-color .2s ease-out;

    &:hover {
        background-color: $color-beige;
    }

    &.is-active {
        background-color: $color-peach;

        &:hover {
            background-color: $color-grey-light;

            .o-icon {
                transform: scale(1.2);
            }
        }
    }

    .o-icon {
        transition: transform .2s ease-out;
    }
}

</style>
