<template>
    <div class="p-directory | l-content">

        <heading
            :heading="page.heading"
            :intro="page.intro"
            :description="page.description"
        />

        <div class="grid">
            <ul
                v-if="regions && regions.length > 0"
                class="p-directory__regions"
            >
                <li
                    v-for="region in regions"
                    :key="`region-${region.slug}`"
                >
                    <router-link
                        :to="`/collection/${region.id}/${region.slug}`"
                        :title="region.title"
                        class="t-t3"
                        v-cursor.city="{ label: 'Découvrir' }"
                    >
                        <anim-text
                            :text="region.title"
                            type="chars"
                            tag="span"
                            :reveal="true"
                            :hover="true"
                        />
                    </router-link>
                </li>
            </ul>

            <slider
                :slides="dynamics"
                ref="sliderDynamics"
            >
                <template #slide="item">
                    <card
                        :count="item.assetCount"
                        :title="item.title"
                        :asset="item.asset"
                        :url="`/collection/${item.id}/${item.slug}`"
                        label="Consulter la thématique"
                        @assetLoaded="$refs.sliderDynamics.resize()"
                    />
                </template>
            </slider>
        </div>
    </div>
</template>

<script>

import Card from 'objects/Card';
import AnimText from 'objects/AnimText';
import Slider from 'components/Slider';
import Heading from 'layout/Heading';

import { mapState } from 'vuex'

export default {
    name: 'Directory',
    components: {
        Card,
        AnimText,
        Slider,
        Heading,
    },
    props: {
        page: {},
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    computed: {
        ...mapState({
            regions: state => state.categories.regions
        }),
        dynamics() {
            const category = this.$store.state.categories.dynamics
            this.fetchCategoryGroupPhotos(category)
            return category
        },
    },
    methods: {
        fetchCategoryGroupPhotos(category) {
            category.forEach(cat => {
                this.$store.dispatch('categories/loadCategory', cat.id)
            })
        },
    }
};

</script>

<style lang="scss">

// .p-directory {}

.p-directory__regions {
    @include reset-list;
    margin-top: 5vh;
    margin-bottom: 5vh;

    li {
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1em;
        padding-right: var(--grid-gutter-half);
        padding-left: var(--grid-gutter-half);

        &.js-reveal {
            opacity: 0;

            &.is-visible {
                opacity: 1;
                transition: opacity .2s ease-out;
            }
        }
    }

    a {
        display: inline-block;
        line-height: 1.2;
    }

    @media #{md("sm")} {
        @include clearfix;

        li {
            width: 50%;

            &:nth-child(2n+1) {
                float: left;
            }

            &:nth-child(2n) {
                float: right;
            }
        }
    }
}

</style>
