<template>
    <ul
        v-if="tags.length > 1"
        class="o-tags"
        v-view.reveal
    >
        <li
            v-for="tag in tags"
            :key="`tag-${tag.slug}`"
            class="o-tags__item | o-tag"
        >
            <router-link
                v-if="link"
                :to="`/collection/${tag.id}/${tag.slug}`"
                :title="tag.title"
                v-html="tag.title"
                class="o-tag__link | t-small"
                v-cursor
            />
            <span
                v-else class="o-tag__link | t-small"
                v-html="tag.title"
            ></span>
        </li>
    </ul>
    <span
        v-else-if="tags.length === 1"
        class="o-tag -single"
    >
        <router-link
            v-if="link"
            :to="`/collection/${tags[0].id}/${tags[0].slug}`"
            :title="tags[0].title"
            v-html="tags[0].title"
            class="o-tag__link | t-small"
        />
        <span
            v-else class="o-tag__link | t-small"
            v-html="tags[0].title"
        ></span>
    </span>
</template>

<script>

export default {
    name: 'Tags',
    props: {
        tags: {
            type: Array,
            required: true,
        },
        link: {
            type: Boolean,
            default: true,
        },
    },
};

</script>

<style lang="scss">


/*----------  Tags list  ----------*/

.o-tags {
    --reveal-end: 1;

    margin: .2em -.3em;


    &.js-reveal {

        .o-tags__item {
            opacity: 0;
            transform: translate(0, 1em) scale(1, .5);
            transform-origin: 50% 100%;
        }

        &.is-visible {

            .o-tags__item {
                opacity: 1;
                transform: scale(1);
                transition: opacity .6s ease-out .4s;
            }
        }
    }
}

.o-tags__item {
    margin: .3em;
}


/*----------  Tag  ----------*/

.o-tag {
    display: inline-block;
    padding-right: .2em;
    padding-left: .2em;
    color: $color-dark;
    background-color: $color-blue-light;
    transition: background-color .2s ease-out;

    &.-single {
        margin-top: .5em;
        margin-bottom: .5em;
    }

    &:hover {
        background-color: $color-peach;
    }
}

.o-tag__link {

}

</style>
