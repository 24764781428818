<template>
    <div
        class="l-footer"
        :class="{ 'is-visible' : isVisible }"
    >
        <button
            class="o-btn-trigger | t-small"
            @click="isVisible = !isVisible"
            v-cursor
        >
            <span class="o-btn-trigger__bg"></span>
            <anim-text
                text="Crédits"
                type="words"
                tag="span"
                class="o-btn-trigger__label -open"
            />
            <anim-text
                text="Fermer"
                type="words"
                tag="span"
                class="o-btn-trigger__label -close"
            />
        </button>
        <footer class="l-footer__inner">
            <div
                v-for="(credit, i) in credits"
                :key="`credit-${i}`"
                class="l-footer__cell"
            >
                <h4 class="l-footer__title | t-small">{{ credit.title }}</h4>
                <a
                    :href="credit.url"
                    :title="credit.label"
                    :target="credit.target"
                    :rel="credit.target === '_blank' ? 'noopener' : false"
                    v-cursor
                >
                    <icon :icon="credit.icon" />
                </a>
            </div>
            <span class="l-footer__cell -copy">
                <span class="t-small">&copy; {{ currentYear }}</span>
            </span>
        </footer>
    </div>
</template>

<script>

import Icon from 'objects/Icon'
import AnimText from 'objects/AnimText';

export default {
    name: 'AppFooter',
    components: {
        Icon,
        AnimText,
    },
    data: () => ({
        isVisible: false,
        credits: [
            {
                title: 'Une initiative du',
                url: 'https://www.mbsl.qc.ca',
                target: '_blank',
                label: 'Musée du Bas-Saint-Laurent',
                icon: 'mbsl',
            },
            {
                title: 'Financé par',
                url: 'https://www.riviereduloup.ca',
                target: '_blank',
                label: 'MRC de Rivière du Loup',
                icon: 'mrc-rdl',
            },
            {
                title: 'En partenariat avec',
                url: 'http://www.hydroquebec.com',
                target: '_blank',
                label: 'Hydro Québec',
                icon: 'hydroquebec',
            },
            {
                title: 'Design et développement par',
                url: 'https://mambomambo.ca/',
                target: '_blank',
                label: 'MamboMambo studio de design graphique et web à Québec',
                icon: 'mambo',
            },
        ]

    }),
    mounted() {

        window.addEventListener('scrollstart', () => {
            this.isVisible = false
        })
    },
    computed: {
        currentYear: () => new Date().getFullYear(),
    },
};

</script>

<style lang="scss">

.l-footer {
    width: 100%;

    &.is-visible {

        .o-btn-trigger__bg {
            transform: scale(1);
        }

        .o-btn-trigger__label {

            &.-open .o-at__w-inner {
                animation: at-word-out-up .3s ease-in forwards;
            }

            &.-close .o-at__w-inner {
                animation: at-word-in-down .2s ease-out .2s forwards;
            }
        }
    }

    @media #{md("sm", "max")} {
        margin-top: 10vh;
        padding-top: 1em;
        padding-bottom: 1em;

        &:before {
            @include pseudo-el($bg: $color-grey);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @media #{md("sm")} {
        z-index: 100;
        position: fixed;
        bottom: 0;
        left: 200vw;
        transition: left 0s .8s;

        &.is-visible {
            left: 0;
            transition: left 0s 0s;

            .l-footer__inner {
                transform: translate(0);
                transition: transform .8s $out-circ;
            }
        }
    }
}

.l-footer__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--grid-gutter-half);
    color: $color-dark;

    @media #{md("sm", "max")} {
        width: calc(100% - #{$grid-gutter--mobile} * 2);
        margin-left: $grid-gutter--mobile;
    }

    @media #{md("xs")} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        width: calc(100% - #{$header-size});
        margin-left: $header-size;
        padding-left: var(--grid-gutter-half);
        font-size: .75rem;
        transform: translate(0, 100%);
        transition: transform .8s $out-circ;
        will-change: transform;
        padding-right: $header-size;

        &:before {
            @include pseudo-el($bg: $color-grey);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @media #{md("md")} {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        font-size: 1rem;
    }
}

.l-footer__cell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 12em;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1em;
    padding-bottom: 1em;

    &.-copy {
        grid-column: 1/3;
        text-align: center;
    }

    a {
        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
    }

    @media #{md("xs", "max")} {
        text-align: center;
    }

    @media #{md("xs")} {

        &.-copy {
        }
    }

    @media #{md("sm")} {

        &.-copy {
            position: absolute;
            right: -$header-size;
            bottom: 0;
            width: $header-size;
            grid-column: 4/5;
            padding-right: 1em;
            text-align: right;
        }
    }
}

.l-footer__title {
    display: block;
    margin-bottom: 1.5em;
    text-transform: none;

    &:after {
        @include pseudo-el($height: 1px, $bg: currentColor);
        margin-top: 1em;
    }
}


/*----------  Trigger btn  ----------*/

.o-btn-trigger {
    padding-top: 1em;
    padding-bottom: 1em;
    color: inherit;
    cursor: pointer;

    &:after {
        @include pseudo-el($height: 1px, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scale(0, 1);
        transform-origin: 100% 50%;
        transition: transform .8s $out-expo;
    }

    &:hover:after {
        transform: scale(1);
        transform-origin: 0 50%;
    }

    .o-at {
        justify-content: center;
        width: 100%;
    }

    @media #{md("sm", "max")} {
        display: none;
    }

    @media #{md("sm")} {
        position: fixed;
        bottom: 0;
        left: 0;
        width: $header-size;
        border-top: 1px solid currentColor;
        transition: all $grid-layout-duration-out/2 ease-out $grid-layout-duration-out;

        .nav-is-open & {
            border-color: $color-light;
            transition: all $grid-layout-duration-in/3 ease-out 0s;
        }
    }
}

.o-btn-trigger__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: calc(100% + 1px);
    background-color: $color-grey;
    transform: scale(1, 0);
    transform-origin: 50% 100%;
    transition: transform .8s $out-circ;
}

.o-btn-trigger__label {
    color: $color-dark;

    &.-open {
        .o-at__w-inner {
            animation: at-word-in-up .2s ease-out .2s forwards;
        }
    }

    &.-close {
        position: absolute;
        bottom: 1em;

        .o-at__w-inner {
            animation: at-word-out-down .3s ease-in 0s forwards;
        }
    }

    @media #{md("sm")} {
        transition: inherit;

        .nav-is-open & {
            color: $color-light;
        }
    }
}


</style>
