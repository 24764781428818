<template>
    <article
        class="o-card"
        @click="click()"
        v-cursor
    >
        <header class="o-card__header">
            <h3 class="o-card__title | t-t3">
                {{ title }}
            </h3>
            <span class="o-card__data">
                <span
                    v-if="count !== null"
                    class="o-card__count | t-h5"
                >
                    {{ count }} photos
                </span>
                <span
                    v-if="date !== null"
                    class="o-card__dates | t-h5"
                >
                    {{ date }}
                </span>
            </span>
        </header>

        <asset
            v-if="asset"
            v-bind="asset"
            crop="grid"
            :reveal="false"
            :grayscale="true"
            @load="$emit('assetLoaded')"
            class="o-card__asset"
        />

        <btn
            v-if="url"
            size="sm"
            modifier="hover"
            :label="label"
            iconAfter="arrow-right"
            class="o-card__btn"
        />
    </article>
</template>

<script>

import Asset from 'objects/Asset'
import Btn from 'components/Btn'

export default {
    name: 'Card',
    components: {
        Asset,
        Btn,
    },
    props: {
        count: {
            type: Number,
            default: null,
        },
        date: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: 'Consulter le fonds',
        },
        asset: {
            type: Object,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
    },
    computed: {
        className() {
            let classname = 'o-card'

            return classname
        }
    },
    methods: {
        click() {
            if (this.url) {
                this.$router.push(this.url)
            }
        }
    }
};

</script>

<style lang="scss">

.o-card {
    padding: 1em;
    background-color: $color-light;
    white-space: normal;

    &:hover {

        .o-asset__inner {
            transform: scale(1.1);
            transition: transform 5s linear;
        }

        .o-card__btn:after {
            transform: scale(1);
            transform-origin: 0 50%;
        }
    }
}

.o-card__header {
    display: flex;
    flex-direction: column-reverse;
}

.o-card__title {
    margin-top: .25em;
    margin-bottom: .25em;
}

.o-card__data {
    display: flex;
    color: $color-dark;
}

.o-card__count,
.o-card__dates {
    flex: 1 1 0;
}

.o-card__dates {
    text-align: right;
}

.o-card__asset {
    margin-bottom: 5vmax;
    max-height: 24em;
    overflow: hidden;

    .o-asset__inner {
        transition: transform .4s ease-out;
    }
}

.o-card__btn {
    cursor: pointer;
}


</style>
