import Vue from 'vue'
import Vuex from 'vuex'

import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'

import global from './modules/global'
import pages from './modules/pages'
import photos from './modules/photos'
import categories from './modules/categories'
import search from './modules/search'
import loader from './modules/loader'

// Vuex
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        global,
        pages,
        photos,
        categories,
        search,
        loader,
    },
    strict: process.env.NODE_ENV !== 'production'
})

export default store
