import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from 'src/store/index'
import view from './directives/view'
import cursor from './directives/cursor'

import { resizeEnd } from 'src/utils';
import 'src/filters'

import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm';

// Add window resizeEnd event
resizeEnd()

// Var
Vue.config.productionTip = false

// Meta descriptions
Vue.use(VueMeta, {})

// Production
const isProd = process.env.NODE_ENV === 'production';

// GTM
if ( isProd && process.env.VUE_APP_GTM ) {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GTM,
        queryParams: {
            gtm_auth: process.env.VUE_APP_GTM_AUTH,
            gtm_cookies_win:'x'
        },
        defer: true,
        enabled: true,
        debug: false,
        vueRouter: router,
        loadScript: true,
        // ignoredViews: [''],
        // trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    });
}


// Initial load
store.dispatch('global/initLoad')
    .then(() => {

        // Vue instance
        new Vue({
            el: '#app',
            store,
            router,
            render: h => h(App),
            mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
        }).$mount('#app')
    })
