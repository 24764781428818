import { fetchApi, fetchJSON, categoriesJSON } from 'src/graphql/config'
import queries from 'src/graphql/queries'

/*
 * Pages
 */

// State
const state = {
    categories: {},
    regions: [],
    funds: [],
    dynamics: [],
    tags: [],
}

// Getters
const getters = {
    getCategoryGroup: state => category => state[category],
    getCategoryCount: state => category => state[category].length,
    getCategoryById: state => id => state.categories[id],
    getSortedCategory: state => category => [... state[category]].sort()
}

// Actions
const actions = {
    loadAll(store) {

        return fetchJSON(categoriesJSON)
            .then(r => {
                let categories
                for(let categoryGroup of Object.keys(r)) {
                    categories = r[categoryGroup]
                    store.commit('addCategoryGroup', {groupHandle: categoryGroup, data: categories})

                    categories.forEach(cat => {
                        store.commit('addCategory', cat)
                    })
                }
            })
    },
    loadCategory(store, id) {

        return new Promise((resolve, reject) => {

            // Search if category already exists
            let category = store.getters.getCategoryById(id)

            // If category already exists and is single fetched
            if(typeof category !== 'undefined' && category.isSingleFetch) {
                resolve(category)
            // Load category if doesn't exist or is not single fetch
            } else {
                fetchApi(queries.category(id))
                    .then(r => {

                        if (r && (r.category || r.tag)) {

                            let data = r.category ? r.category : r.tag

                            data = {...Object.assign(category, data)}

                            // Set asset
                            data.asset = r.asset && r.asset.length > 0 ? r.asset[0] : null

                            data.isSingleFetch = true
                            store.commit('addCategory', data)
                            resolve(data)

                        } else {
                            reject({
                                code: 404,
                                message: 'Collection not found'
                            })
                        }
                    })
                    .catch(e => {

                        reject({
                            code: 404,
                            message: e
                        })
                    })
            }

        })
    },
}

// Mutations
const mutations = {
    addCategoryGroup(state, {groupHandle, data}) {
        state[groupHandle] = data
    },
    addCategory(state, cat) {
        state.categories[cat.id] = cat
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
