<template>
    <header
        class="l-header"
        :class="[
            { 'is-opening' : isOpening },
            { 'is-closing' : isClosing },
        ]"
    >
        <span class="l-header__sitename">
            <btn
                tag="router-link"
                href="/"
                size="sm"
                modifier="hover"
                label="Cyberphotos"
                class=""
            />
        </span>
        <button
            class="l-header__btn | o-btn-toggle"
            @click="navIsOpen ? close() : open()"
            v-cursor
        >
            <span class="o-btn-toggle__icon">
                <span class="o-btn-toggle__line"></span>
                <span class="o-btn-toggle__line"></span>
                <span class="o-btn-toggle__line"></span>
            </span>
            <span class="o-btn-toggle__label | t-small">
                <anim-text
                    text="Menu"
                    type="words"
                    tag="span"
                    class="o-btn-toggle__open"
                />
                <anim-text
                    text="Fermer"
                    type="words"
                    tag="span"
                    class="o-btn-toggle__close"
                />
            </span>
        </button>
        <nav class="l-nav" itemscope itemtype="http://schema.org/SiteNavigationElement">
            <ul class="l-nav__list | o-boxed">
                <li
                    v-for="(item, i) in nav"
                    :key="`nav-item-${i}`"
                    class="l-nav__item"
                    itemprop="name"
                >
                    <router-link
                        :to="item.sectionHandle|resolver"
                        class="l-nav__link"
                        :title='item.title'
                        itemprop="url"
                        exact
                        @click.native="click(item.sectionHandle)"
                        @mouseenter.native="hoverIndex = i"
                        @mouseleave.native="hoverIndex = -1"
                        @mousemove.native="mousemove"
                        v-cursor
                    >
                        <anim-text
                            ref="navItem"
                            :text="item.title"
                            :hover="true"
                            tag="span"
                            type="chars"
                            class="t-t1"
                        />
                    </router-link>

                    <asset
                        v-for="(asset, j) in item.assets"
                        :key="`nav-item-${i}-asset-${j}`"
                        v-bind="asset"
                        class="l-nav__asset"
                        :class="[
                            `-link-${i+1}`,
                            `-i${j+1}`,
                            { 'is-visible' : hoverIndex === i },
                        ]"
                        :reveal="false"
                        :hidden="true"
                        :grayscale="true"
                        crop="grid"
                    />
                </li>
            </ul>
        </nav>
        <a
            href="https://www.mbsl.qc.ca"
            title="Musée du Bas-Saint-Laurent"
            to="MBSL"
            class="l-header__mbsl"
            target="_blank"
            rel="noopener"
            v-cursor
        >
            <icon icon="mbsl-icon" />
        </a>

        <btn-circle
            tag="router-link"
            href="/recherche"
            icon="search"
            label="Recherche"
            size="lg"
            color="blue"
            class="l-header__search"
            :hidden="hideSearchBtn"
        />

    </header>
</template>

<script>

import Icon from 'objects/Icon'
import Asset from 'objects/Asset'
import AnimText from 'objects/AnimText';
import BtnCircle from 'objects/BtnCircle';
import Btn from 'components/Btn'

export default {
    name: 'AppHeader',
    components: {
        Icon,
        Asset,
        AnimText,
        BtnCircle,
        Btn,
    },
    data: () => ({
        navIsOpen: false,
        isOpening: false,
        isClosing: false,
        currentScroll: 0,
        hoverIndex: -1,
    }),
    computed: {
        nav() {
            return this.$store.state.global.nav
        },
        hideSearchBtn() {
            // Hide if has tutorial or search in active
            return ((this.$store.getters['global/getCurrentPageName'] === 'home' && this.$store.getters['global/showCanvasTutorial']) || this.$store.getters['global/searchIsActive'])
        },
    },
    methods: {
        open() {
            if(this.navIsOpen) return;

            this.navIsOpen = true
            this.isOpening = true

            this.$refs.navItem.forEach(item => {
                item.isVisible = true
            })

            // Emit event
            this.$emit('toggleNav', this.navIsOpen)

            setTimeout(() => {
                this.isOpening = false
                this.currentScroll = window.scrollY
                document.documentElement.style.overflowY = 'hidden';
            }, 1000);
        },
        close() {
            if(!this.navIsOpen) return;

            this.navIsOpen = false
            this.isClosing = true

            this.$refs.navItem.forEach(item => {
                item.isVisible = false
            })

            // Add previous scroll
            document.documentElement.style.overflowY = 'scroll';
            window.scrollTo(0, this.currentScroll)

            // Emit event
            this.$emit('toggleNav', this.navIsOpen)

            setTimeout(() => {
                this.isClosing = false
            }, 1000);
        },
        click(handle) {
            if(this.$route.meta.section === handle) {
                this.close()
            }
        },
        mousemove(e) {
            let $target = e.currentTarget;

            let move = Math.round(((e.pageX - $target.offsetLeft) + (e.pageY - $target.offsetTop) * 2) / ($target.offsetWidth + $target.offsetHeight * 2) * 100)/100;

            $target.parentElement.style.setProperty('--mouse-move', `${move}`);
        }
    },
};

</script>

<style lang="scss">

.l-header {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;

    &.is-opening,
    &.is-closing {
        pointer-events: none;

        .l-nav__link .o-at__w {
            overflow: hidden;
        }

        .l-nav__asset {
            --reveal-duration: .3s;
            --reveal-delay: 0s;
        }

        .o-btn-toggle__line {
            transition: transform $grid-layout-duration-in ease-in-out;
        }
    }

    &.is-opening {

        .l-header__sitename {
            transition: color $grid-layout-duration-in/3 ease-out $grid-layout-duration-in/1.2;
        }

        .l-header__mbsl {
            transition: opacity $grid-layout-duration-in/3 ease-out $grid-layout-duration-in;
        }

        .l-nav__link .o-at__w:after {
            --hover-extra-delay: #{$grid-layout-duration-in};
        }
    }

    &.is-closing {

        .l-header__sitename {
            transition: color $grid-layout-duration-out/2 ease-out $grid-layout-duration-out/5;
        }

        .l-header__mbsl {
            transition: opacity $grid-layout-duration-out/4 ease-in;
        }

        .l-nav__link .o-at__w:after {
            transition: none;
        }
    }

    @media #{md("sm", "max")} {
        width: 100%;
        height: $header-size;
        border-bottom: 1px solid currentColor;
    }

    @media #{md("sm")} {
        width: $header-size;
        height: 100%;

        &.is-opening {

            .l-header__sitename {
                transition: color $grid-layout-duration-in/2 ease-out $grid-layout-duration-in/3;
            }

            .l-header__mbsl {
                transition: color $grid-layout-duration-in/3 ease-out;
            }
        }

        &.is-closing {

            .l-header__mbsl {
                transition: color $grid-layout-duration-out/3 ease-out $grid-layout-duration-out/1.5;
            }
        }
    }
}

.l-header__sitename {
    z-index: 1;
    display: inline-flex;
    align-items: center;

    .nav-is-open & {
        color: $color-light;
    }

    @media #{md("sm", "max")} {
        height: 100%;
    }

    @media #{md("sm")} {
        position: absolute;
        top: calc(50% + 6em/2);
        left: calc(#{$header-size}/2 - 1.2em/2 - .1em);
        width: 6em;
        height: 1.2em;
        text-align: center;
        transform: rotate(-90deg);
        transform-origin: 0 0;
    }
}

.l-header__mbsl {
    position: absolute;
    transition: color .2s ease-out, transform .3s $in-out-quad;

    &:hover,
    .nav-is-open &:hover {
        color: $color-red;
        transform: scale(1.1);
    }

    @media #{md("sm", "max")} {
        top: calc(50% - 2em/2);
        right: calc(#{$header-size}/2 - 2em/2);
        color: $color-light;
        pointer-events: none;
        opacity: 0;

        .nav-is-open & {
            opacity: 1;
            pointer-events: initial;
        }
    }

    @media #{md("sm")} {
        bottom: 4em;
        left: calc(50% - 2em/2);
        display: block;
        color: $color-dark;

        .nav-is-open & {
            color: $color-light;
        }
    }
}

.l-header__search {
    --transition-delay-visible: #{$grid-layout-duration-out};

    position: fixed;
    right: $grid-gutter--mobile;
    bottom: $grid-gutter--mobile;
}


/*----------  Navigation  ----------*/

.l-nav {
    position: fixed;
    top: calc( env(safe-area-inset-top) + 0px);
    left: -200%;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: $color-grey;
    transition: left 0s $grid-layout-duration-out;

    .nav-is-open & {
        left: 0;
        transition: left 0s 0s;
    }

    .o-grid-layout__item {
        transform: translate(0, 100%);
        background-color: $color-dark;
        border-color: $color-grey;
    }

    @media #{md("sm", "max")} {
        padding-top: $header-size;
        padding-right: $grid-gutter--mobile;
        padding-left: $grid-gutter--mobile;
    }

    @media #{md("sm")} {
        padding-right: $header-size;
        padding-left: $header-size;
    }
}

.l-nav__list {
    position: static;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-right: calc(1/24 * 100%);
    padding-left: calc(1/24 * 100%);
    list-style: none;
    text-align: left;

    // Hide scrollbar
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: block;
    }

    @media #{md("sm")} {
        padding-right: 0;
    }
    @media #{md("sm")} {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.l-nav__item {
    position: static;
    margin-top: 4vh;
    margin-bottom: 4vh;

    @media #{md("sm")} {
        &:first-of-type {
            padding-top: $header-size;
        }
        &:last-of-type {
            padding-bottom: var(--grid-gutter);
        }
    }
}

.l-nav__link {
    z-index: 1;
    display: inline-block;
    overflow: hidden;
    transition: color .4s ease-out;
    mix-blend-mode: overlay;
    will-change: color;

    .o-at {
       --anim-duration-out: #{$grid-layout-duration-out/2};
    }

    .o-at__w:after {
        top: 1.1em;
    }

    .nav-is-open & {

        &.is-active .o-at__w:after {
            transform: scale(1);
            transform-origin: 0 50%;
        }

        .o-at {
            --anim-duration-in: #{$grid-layout-duration-in};
            --anim-delay: #{$grid-layout-duration-in/3};
        }
    }

    &:hover {

        .o-at {
            color: $color-light;
        }

        .o-at__w:after {
            transform: scale(1);
            transform-origin: 0 50%;
        }

        & ~ .l-nav__asset {
            opacity: 1;
        }
    }

    @media #{md("xs", "max")} {

        .o-at {
           --anim-duration-out: 0;
        }
    }
}

.l-nav__asset {
    --reveal-duration: .5s;
    position: absolute;
    margin-left: -1px;
    pointer-events: none;
    transform: translate(0, calc(var(--mouse-move) * 20% * var(--quotient, 1)));
    will-change: transform;

    .is-touch & {
        display: none;
        visibility: hidden;
    }

    &.is-hidden .o-asset__inner {
        background-color: transparent;
    }

    @for $i from 1 through 3 {

        &:nth-last-child(#{$i}) {
            $i: $i - 1;
            --reveal-delay: calc(#{$i}s/6);
        }
    }

    &.-i1 {
        --quotient: .8;
    }

    &.-i2 {
        --quotient: -1;
    }

    &.-i3 {
        --quotient: 1.2;
    }

    &.-i3 {
        --quotient: -.6;
    }

    @media #{md("sm", "max")} {

        &.-i1 {
            top: 20%;
            left: $grid-gutter--mobile;
            width: 20%;
        }

        &.-i2 {
            bottom: 20%;
            left: 50%;
            width: 35%;
        }

        &.-i3,
        &.-i4 {
            display: none;
        }
    }

    @media #{md("sm")} {

        &.-i1 {
            top: 20%;
            left: calc(#{$header-size} + 1px);
            width: calc((80% - #{$header-size} * 2)/4);
        }

        &.-i2 {
            bottom: 10%;
            left: calc(1 * (100% - #{$header-size} * 2)/4 + #{$header-size} + 1px);
            width: calc((60% - #{$header-size} * 2)/4);
        }

        &.-i3 {
            top: 0;
            left: calc(2 * (100% - #{$header-size} * 2)/4 + #{$header-size} + 1px);
            width: calc((90% - #{$header-size} * 2)/4);
        }

        &.-i4 {
            top: 50%;
            left: calc(3 * (100% - #{$header-size} * 2)/4 + #{$header-size} + 1px);
            width: calc((50% - #{$header-size} * 2)/4);
        }

        &.-link-1 {

            &.-i1 {
                top: 30%;
            }
        }

        &.-link-2 {

            &.-i1 {
                top: 50%;
            }
        }

        &.-link-3 {

            &.-i4 {
                top: 25%;
            }
        }

        &.-link-4 {

            &.-i2 {
                bottom: 25%;
            }
        }
    }
}


/*----------  Nav button  ----------*/

.o-btn-toggle {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: $header-size;
    height: $header-size;
    cursor: pointer;
    user-select: none;

    &:before {
        @include pseudo-el($bg: $color-light);
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(1, 0);
        transform-origin: 50% 100%;
        transition: transform $grid-layout-duration-out/2 $out-sine 0s;
    }

    .nav-is-open & {

        &:before {
            transform: scale(1);
            transition: transform $grid-layout-duration-in/2 $out-sine $grid-layout-duration-in/2;
        }
    }

    &:hover {

        .o-btn-toggle__line {

            &:nth-child(1),
            &:nth-child(3) {
                transform: translate(50%, 0);
            }

            &:nth-child(2) {
                transform: scale(.5, 1) translate(50%, 0);
            }

            .nav-is-open & {

                &:nth-child(1),
                &:nth-child(3) {
                    transform: translate(25%, 0);
                }

                &:nth-child(2) {
                    transform: scale(.5, 1) translate(100%, 0);
                }
            }
        }
    }

    @media #{md("sm", "max")} {
        border-right: 1px solid currentColor;
    }

    @media #{md("sm")} {
        border-bottom: 1px solid currentColor;
    }
}

.o-btn-toggle__icon {
    display: block;
    width: 1.25em;
    height: 1em;
    color: $color-dark;
    overflow: hidden;
}

.o-btn-toggle__line {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform $grid-layout-duration-out ease-in-out;

    &:nth-child(1) {
        top: 0;
    }

    &:nth-child(2) {
        top: calc(50% - 1px/2);
        transform: scale(.5, 1);
        transform-origin: 0 50%;
    }

    &:nth-child(3) {
        bottom: 0;
    }

    .nav-is-open & {

        &:nth-child(1),
        &:nth-child(3) {
            transform: translate(100%, 0);
        }

        &:nth-child(2) {
            transform: scale(1);
        }
    }
}

.o-btn-toggle__label {
    display: block;
    margin-top: .7em;
    width: $header-size;
    color: $color-dark;
    overflow: hidden;
}

.o-btn-toggle__open,
.o-btn-toggle__close {
    justify-content: center;
}


.o-btn-toggle__open {

    .o-at__w-inner {
        transform: translate(0);
        transition: transform 0s .2s;
        animation: at-word-in-up .2s ease-out .2s;
    }

    .nav-is-open & {

        .o-at__w-inner {
            transform: translate(-100%);
            transition: transform 0s .4s;
            animation: at-word-out-up .3s ease-in .4s;
        }
    }
}

.o-btn-toggle__close {
    position: absolute;
    left: 0;
    bottom: 0;
    display: inline-block;
    width: 100%;

    .o-at__w-inner {
        animation: at-word-out-down .3s ease-in 0s forwards;
    }

    .nav-is-open & {

        .o-at__w-inner {
            animation: at-word-in-down .2s ease-out .6s forwards;
        }
    }
}

@keyframes nav-label-in {
    0% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0);
    }
}

@keyframes nav-label-out {
    0% {
        transform: translate(0);
    }
    100% {
        transform: translate(0, -100%);
    }
}

</style>
