<template>
    <div :class="className">
        <input
            :id="`input-${_uid}`"
            class="o-input__input"
            v-model="value"
            :type="type"
            :required="required"
            :autocomplete="autocomplete !== false"
            :name="autocomplete"
            @focus="isActive = true"
            @blur="isActive = false"
        />
        <label
            v-if="(label || !!$slots.default) && !disabled"
            :for="`input-${_uid}`"
            class="o-input__label"
        >
            <slot>
                <anim-text
                    :text="required ? `${label}*` : label"
                    type="words"
                    tag="span"
                    class="o-input__text"
                />
            </slot>
        </label>
        <span
            v-if="hasReset && value"
            class="o-input__reset"
            @click="reset()"
            v-cursor
        >
            <icon
                icon="cross"
                size="xs"
            />
        </span>
    </div>
</template>

<script>

import Icon from 'objects/Icon';
import AnimText from 'objects/AnimText';

export default {
    name: 'AppInput',
    components: {
        Icon,
        AnimText,
    },
    props: {
        modifier: {
            type: String,
            default: 'text'
        },
        label: false,
        defaultValue: false,
        required: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasReset: {
            type: Boolean,
            default: false,
        },
        autocomplete: false,
    },
    data: () => ({
        value: '',
        isActive: false,
        isFilled: false,
    }),
    mounted() {
        if(this.defaultValue) {
            this.value = this.defaultValue
            this.isFilled = true
        }
    },
    computed: {
        className() {
            let className = `o-input -${this.modifier}`

            if (this.disabled) {
                className += ' is-disabled'
            }
            if (this.isActive || this.isFilled) {
                className += ' is-filled'
            }

            return className
        }

    },
    methods: {
        setValue(value) {
            this.value = value
        },
        reset() {
            this.value = ''
        }
    },
    watch: {
        value(val) {
            this.$emit('input', val)
            if (val !== '') {
                this.isFilled = true
            } else {
                this.isFilled = false
            }
        }
    }
};

</script>

<style lang="scss">

.o-input {
    color: $color-dark;

    &.-text {

        &:before {
            @include pseudo-el($bg: $color-beige);
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(1, 0);
            transform-origin: 50% 100%;
            transition: transform .2s ease-out;
        }

        &:after {
            @include pseudo-el($height: 1px, $bg: $color-dark);
            position: absolute;
            bottom: 0;
            left: 0;
            transition: background-color .2s ease-out;
        }
    }

    &.is-filled {

        &:before {
            transform: scale(1);
        }

        &:after {
            background-color: $color-grey;
        }

        .o-input__label .o-at__w-inner {
            animation: at-word-out-down .2s ease-out forwards;
        }
    }

    &.is-disabled {
        pointer-events: none;

        &:before {
            background-color: $color-grey;
        }

        &:after {
            background-color: transparent;
        }

        .o-input__input {
            color: $color-light;
        }
    }
}

.o-input__input {
    width: 100%;
    padding: 1em .75em;
    color: $color-grey;
}

.o-input__label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: .5em;
    pointer-events: none;

    .o-at__w-inner {
        animation: at-word-in-down .2s ease-out forwards;
    }
}

.o-input__reset {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25em;
    height: 100%;
    color: $color-grey;
}

</style>
