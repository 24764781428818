/*
 * GraphQL Configuration and variables
 */

export const api = '/api'
export const categoriesJSON = '/categories.json'
export const photosJSON = '/photos.json'

export const photosVolume = 'photos'
export const staticVolume = 'images'

export const fetchApi = query => {

    return fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query })
        })
        .then(r => r.json())
        .then(r => r.data)
        .catch(error => {
            console.error('fetchApi error:', error)  // eslint-disable-line
            return error
        })
}


export const fetchJSON = url => {

    return fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(r => r.json())
        .then(r => r)
        .catch(error => {
            console.error('fetchJSON error:', error)   // eslint-disable-line
            return error
        })
}
