import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'
/*
** Search
*/

// State
const state = {
    query: '',
    count: -1,
}

// Getters
const getters = {
    hasQuery: state => state.query !== '',
}

// Actions
const actions = {
    deleteQuery(store) {
        store.commit('setQuery', '')
    },
    updateQuery(store, query) {
        store.commit('setQuery', query)
    },
    fetchResultCount(store, query) {
        store.commit('setCount', -1)

        return new Promise((resolve, reject) => {

            fetchApi(queries.searchCount(query))
                .then(r => {

                    if (r && r.assetCount) {

                        let count = r.assetCount

                        // Format with spaces
                        count = count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

                        store.commit('setCount', count)
                        resolve(count)

                    } else {
                        reject({
                            code: 404,
                            message: 'Errors with the search query'
                        })
                    }
                })
                .catch(e => {

                    reject({
                        code: 404,
                        message: e
                    })
                })
        })
    },
    resetResultCount(store) {
        store.commit('setCount', -1)
    }
}

// Mutations
const mutations = {
    setQuery(state, query) {
        state.query = query
    },
    setCount(state, count) {
        state.count = count
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
