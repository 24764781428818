<template>
    <component
        v-if="src"
        :is="isLink ? 'router-link' : tag"
        :to="to"
        :title="isLink ? alt : false"
        :style="`--ratio: ${ratio}`"
        :class="className"
        v-view.reveal="reveal"
    >
        <div
            ref="inner"
            class="o-asset__inner"
            v-cursor="isLink"
        >
            <div
                v-if="hover"
                class="o-hover"
            >
                <tags
                    v-if="categoryFund"
                    :tags="categoryFund"
                    :link="false"
                    class="o-hover__tags"
                />
                <span
                    v-if="date"
                    class="t-small"
                >
                    {{ date }}{{ dateEnd && dateEnd > date ? ` - ${dateEnd}` : '' }}
                </span>
                <span
                    v-if="cleanTitle"
                    class="o-hover__title | t-t4"
                >
                    {{ cleanTitle }}
                </span>
            </div>

            <picture class="o-asset__img">
                <source :media="`(max-width: ${mq.xxs.width}px)`" :srcset="getSource(crop, 'xxs', 'webp')" type="image/webp">
                <!-- <source :media="`(max-width: ${mq.xxs.width}px)`" :srcset="getSource(crop, 'xxs', 'jpeg')" type="image/jpeg"> -->

                <source :media="`(max-width: ${mq.xs.width}px)`" :srcset="getSource(crop, 'xs', 'webp')" type="image/webp">
                <source :media="`(max-width: ${mq.xs.width}px)`" :srcset="getSource(crop, 'xs', 'jpeg')" type="image/jpeg">

                <source :media="`(min-width: ${mq.xs.width}px)`" :srcset="getSource(crop, ['sm', 'md', 'lg', 'xl'], 'webp')" type="image/webp">
                <!-- <source :media="`(min-width: ${mq.xs.width}px)`" :srcset="getSource(crop, ['sm', 'md', 'lg', 'xl'], 'jpeg')" type="image/jpeg"> -->
                <source :media="`(min-width: ${mq.xs.width}px)`" :srcset="getSource(crop, ['md', 'xl'], 'jpeg')" type="image/jpeg">

                <img
                    v-if="getSource(crop, 'default')"
                    :src="getSource(crop, 'default')"
                    :alt="alt"
                    :width="width"
                    :height="height"
                    :sizes="sizes"
                    ref="img"
                    @load="loaded()"
                    onContextMenu="return false"
                >
            </picture>

            <btn-circle
                v-if="zoom"
                icon="arrow-expand"
                color="white"
                class="o-asset__btn -zoom-in"
                aria-label="Zoomer l'image"
                @click.native="zoomIn()"
            />
        </div>
        <div
            v-if="zoom"
            ref="zoom"
            class="o-zoom"
            @mousemove="pan"
        >
            <picture
                class="o-zoom__picture"
                ref="zoomPicture"
            >
                <source :media="`(max-width: ${mq.xxs.width}px)`" :srcset="getSource('fullscreen', 'xxs', 'webp')" type="image/webp">
                <!-- <source :media="`(max-width: ${mq.xxs.width}px)`" :srcset="getSource('fullscreen', 'xxs', 'jpeg')" type="image/jpeg"> -->

                <source :media="`(max-width: ${mq.xs.width}px)`" :srcset="getSource('fullscreen', 'xs', 'webp')" type="image/webp">
                <source :media="`(max-width: ${mq.xs.width}px)`" :srcset="getSource('fullscreen', 'xs', 'jpeg')" type="image/jpeg">

                <source :media="`(min-width: ${mq.xs.width}px)`" :srcset="getSource('fullscreen', ['sm', 'md', 'lg', 'xl'], 'webp')" type="image/webp">
                <!-- <source :media="`(min-width: ${mq.xs.width}px)`" :srcset="getSource('fullscreen', ['sm', 'md', 'lg', 'xl'], 'jpeg')" type="image/jpeg"> -->
                <source :media="`(min-width: ${mq.xs.width}px)`" :srcset="getSource('fullscreen', ['md', 'xl'], 'jpeg')" type="image/jpeg">

                <img
                    v-if="getSource('fullscreen', 'xl')"
                    :src="getSource('fullscreen', 'xl')"
                    :alt="alt"
                    sizes="100w"
                    ref="zoomImg"
                    class="o-zoom__img"
                    onContextMenu="return false"
                >
            </picture>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="o-zoom__watermark"
                ref="zoomWatermark"
            >
                <defs>
                    <pattern id="watermark" patternUnits="userSpaceOnUse" width="200" height="200" x="50" y="50">
                        <path fill="#CABDBD" d="M12.2,23.8c-1.6,0.7-2.7,2.4-2.6,4.3l0.6,17.2C4.1,40.7,0,33.3,0,25C0,11.2,11.2,0,25,0c7.2,0,13.5,3,18.1,7.8
                            v8c0,0.6-0.4,0.8-0.6,0.9s-0.7,0.3-1.1-0.2l-7.7-7.1c-1.3-1.2-3.2-1.5-4.9-0.7c-1.6,0.7-2.7,2.4-2.6,4.3L27,31.3
                            c0,0.7-0.4,0.8-0.6,0.9c-0.2,0.1-0.7,0.3-1.1-0.2l-8.2-7.5C15.8,23.4,13.9,23,12.2,23.8z"/>
                        <path fill="#CABDBD" d="M50,25c0,13.8-11.2,25-25,25c-4,0-7.8-0.9-11.1-2.6l-0.7-19.5c0-0.6,0.4-0.8,0.6-0.9c0.2-0.1,0.7-0.3,1.1,0.2
                            l8.1,7.5c1.4,1.2,3.2,1.5,4.9,0.7c1.6-0.7,2.7-2.4,2.6-4.3l-0.8-18.4c0-0.6,0.4-0.8,0.6-0.9s0.7-0.3,1.1,0.2l7.8,7.1
                            c1.3,1.2,3.2,1.5,4.9,0.7c1.6-0.7,2.7-2.4,2.6-4.2v-3.2C48.8,16.1,50,20.4,50,25z"/>
                    </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#watermark)"/>
            </svg>

            <btn-circle
                icon="arrow-reduce"
                color="white"
                class="o-asset__btn | o-zoom__btn"
                aria-label="Réduire l'image"
                @click.native="zoomOut()"
            />
        </div>
    </component>
</template>

<script>

import BtnCircle from 'objects/BtnCircle';
import Tags from 'objects/Tags';

import Variables from 'mixins/variables'
import { EventBus } from 'src/event-bus'
import { gsap } from 'gsap/all'

export default {
    name: 'Asset',
    components: {
        Tags,
        BtnCircle,
    },
    props: {
        url: {
            type: String,
            default: null,
        },
        awsTransformPath: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        cleanTitle: {
            type: String,
            default: null,
        },
        date: {
            type: Number,
            default: null,
        },
        dateEnd: {
            type: Number,
            default: null,
        },
        categoryFund: {
            type: Array,
            default: null,
        },
        width: {
            type: Number,
            default: null,
        },
        height: {
            type: Number,
            default: null,
        },
        tag: {
            type: String,
            default: 'div',
        },
        path: {
            type: String,
            default: null,
        },
        crop: false,
        sizes: false,
        zoom: {
            type: Boolean,
            default: false,
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
        hover: {
            type: Boolean,
            default: false,
        },
        contain: {
            type: Boolean,
            default: false,
        },
        grayscale: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [ Variables ],
    data: () => ({
        isLoaded: false,
        zoomActive: false,
        panActive: false,
        currentScroll: 0,
        mq: {
            xxs: {
                width: 480,
                ratio: [1, 2],
            },
            xs: {
                width: 768,
                ratio: [1, 2],
            },
            sm: {
                width: 992,
                ratio: 1,
            },
            md: {
                width: 1200,
                ratio: 1,
            },
            lg: {
                width: 1440,
                ratio: 1,
            },
            xl: {
                width: 1920,
                ratio: 1,
            },
        },
    }),
    mounted() {

        if (this.zoom) {

            // Window events
            window.addEventListener('resizeEnd', this.setSizes);
            if (this.isTouch) {
                window.addEventListener('deviceorientation', this.pan);
            }
        }
    },
    computed: {
        isAWS() {
            return this.awsTransformPath !== null
        },
        isLocal() {
            return this.url !== null
        },
        src() {
            let url = false

            // If AWS asset
            if(this.isAWS) {
                url = this.$store.state.global.cloudfrontUrl + this.awsTransformPath

            // If local asset
            } else if(this.isLocal) {
                url = this.url
            }

            return url
        },
        alt() {
            return this.isAWS ? this.cleanTitle : this.title
        },
        ratio() {
            if (this.width === null || this.height === null) {
                return this.$refs.img.offsetWidth/this.$refs.img.offsetHeight
            }

            return (this.width/this.height).toFixed(3)
        },
        isLink() {
            return this.path !== null
        },
        to() {
            return this.isLink ? `/photo${this.path}` : false
        },
        isPortrait() {
            return this.ratio < 1
        },
        isLandscape() {
            return !this.isPortrait
        },
        className() {
            let classname = 'o-asset'
            classname += this.isLandscape ? ' -landscape' : ' -portrait'

            if (this.zoomActive) {
                classname += ' is-zooming'
            }

            if (this.loaded) {
                classname += ' is-loaded'
            }

            if (this.contain) {
                classname += ' -contain'
            }

            if (this.hidden) {
                classname += ' is-hidden'
            }

            if (this.grayscale) {
                classname += ' -grayscale'
            }

            return classname
        },
    },
    methods: {
        setSizes(){

            if ( !this.$refs.img )
                return

            // Image bounding client rect
            this.rect = this.$refs.img.getBoundingClientRect()

            // Image wider than window width
            const wider = this.ratio > this.W.ratio

            // Zoom image vars
            this.zoomImg = {
                $el: this.$refs.zoomImg,
                scale: wider ? this.rect.height/this.W.h : this.rect.width/this.W.w,
                width: wider ? this.W.h * this.ratio : this.W.w,
                height: wider ? this.W.h : this.W.w/this.ratio,
                wider: wider,
                mouseEvent: null,
            }

            this.zoomImg.x = wider ? (this.W.w - this.zoomImg.width)/2 : 0
            this.zoomImg.y = wider ? 0 : (this.W.h - this.zoomImg.height)/2

            // If zoom active, reset zoom image position
            if (this.zoomActive) {
                gsap.set(this.zoomImg.$el, {
                    width: this.zoomImg.width,
                    height: this.zoomImg.height,
                    x: this.zoomImg.x,
                    y: this.zoomImg.y
                })
            }
        },
        loaded() {
            this.isLoaded = true
            this.$emit('load')

            if (this.zoom) {
                this.setSizes()
            }
        },
        zoomIn() {

            this.setSizes()

            this.zoomActive = true

            gsap
                .timeline()
                .set(this.zoomImg.$el, {
                    x: this.rect.x,
                    y: this.rect.y,
                    width: this.zoomImg.width,
                    height: this.zoomImg.height,
                    scale: this.zoomImg.scale,
                    opacity: 1,
                })
                .to(this.zoomImg.$el, {
                    duration: .8,
                    x: this.zoomImg.x,
                    y: this.zoomImg.y,
                    scale: 1,
                    ease: 'power3.inOut',
                    onComplete: () => {
                        this.panActive = this.zoomImg.wider ? 'x' : 'y'

                        const hoverEvent = {
                            type: `slide-${this.panActive}`
                        }

                        // Add mouse event for cursor
                        EventBus.$emit('cursor-hover-in', hoverEvent)
                        this.zoomImg.$el.addEventListener('mouseenter', this.zoomImg.mouseEvent = () => {
                            EventBus.$emit('cursor-hover-in', hoverEvent)
                        })

                        this.currentScroll = window.scrollY
                        document.documentElement.style.overflowY = 'hidden';
                    }
                })

        },
        zoomOut() {

            if(!this.zoomActive) {
                return
            }

            gsap
                .to(this.zoomImg.$el, {
                    duration: .8,
                    x: this.rect.x,
                    y: this.rect.y,
                    scale: this.zoomImg.scale,
                    ease: 'power3.inOut',
                    onStart: () => {
                        this.panActive = false
                        this.zoomActive = false

                        // Remove mouse event from zoom image and reset cursor
                        this.zoomImg.$el.removeEventListener('mouseenter', this.zoomImg.mouseEvent)
                        EventBus.$emit('cursor-reset')

                        // Add previous scroll
                        document.documentElement.style.overflowY = 'scroll';
                        window.scrollTo(0, this.currentScroll)
                    },
                    onComplete: () => {
                        gsap.set(this.zoomImg.$el, { opacity: 0 })
                    }
                })

        },
        pan(e) {

            if(!this.panActive) {
                return
            }

            let x = 0
            let y = 0

            if(this.zoomImg.wider) {
                if(typeof e.gamma !== 'undefined') {
                    x = Math.min(100, Math.max(0, ((e.gamma + 25) * 4)))/100
                    x = -Math.ceil(x * (this.zoomImg.width - this.W.w))
                } else {
                    x = -Math.ceil(e.clientX/this.W.w * (this.zoomImg.width - this.W.w))
                }
            } else {
                if(typeof e.beta !== 'undefined') {
                    y = Math.min(100, Math.max(0, (e.beta * 2)))/100
                    y = -Math.ceil(y * (this.zoomImg.height - this.W.h))
                } else {
                    y = -Math.ceil(e.clientY/this.W.h * (this.zoomImg.height - this.W.h))
                }
            }

            gsap.to(this.zoomImg.$el, {
                duration: .1,
                x,
                y,
                ease: 'power2.out',
            })

        },
        getSource(size, breakpoints, ext='jpeg') {

            // TODO
            // Crops in craft
            if(this.isLocal) {
                return this.src
            }
            // ENDTODO

            // Return default url if default image requested
            if (breakpoints === 'default') {
                return `${this.src}-${size}-default.${ext}`
            }

            // Convert breakpoints to array
            breakpoints = typeof breakpoints === 'string' ? [breakpoints] : breakpoints

            const totalBps = breakpoints.length

            let key, ratios, totalRatios, value
            let sources = ''

            // Loop through breakpoints
            for (let i = 0; i < totalBps; i++) {
                key = breakpoints[i]
                value = this.mq[key]
                ratios = value.ratio
                totalRatios = typeof ratios === 'object' ? ratios.length : 0

                // Loop through breakpoint ratios if more than one
                if (totalRatios > 1) {
                    let ratio
                    for (let j = 0; j < totalRatios; j++) {
                        ratio = ratios[j]
                        sources += `${this.src}-${size}-${key}${ratio > 1 ? '_' + ratio + 'x' : ''}.${ext} ${ratio}x${ i < totalBps - 1 || j < totalRatios - 1 ? ', ' : ''}`
                    }
                } else {
                    let width = value.width
                    sources += `${this.src}-${size}-${key}.${ext} ${width}w${ i < totalBps - 1 ? ', ' : ''}`
                }
            }

            return sources
        },
    },
    destroyed() {

        if (this.zoom) {
            // Remove Window events
            window.removeEventListener('resizeEnd', this.setSizes);
            if (this.isTouch) {
                window.removeEventListener('deviceorientation', this.pan);
            }
        }
    }
};

</script>

<style lang="scss">

.o-asset {
    --reveal-end: 1;

    display: block;

    &.is-loaded {

        .o-asset__img {
            opacity: 1;
            transition: opacity .2s ease-out, transform .8s $out-expo;
        }

        &:hover {

            .o-hover {
                transform: scale(1);

                @supports (clip-path: polygon(0 0, 0 0)) {
                    clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
                }

                @supports not (clip-path: polygon(0 0, 0 0)) {
                    opacity: 1;
                }

                & + .o-asset__img {
                    transform: scale(.75);
                    transition: transform .8s $out-expo;
                }
            }
        }
    }

    &.is-hidden,
    &.js-reveal {
        --reveal-duration: 1s;
        --reveal-delay: 0s;

        .o-asset__inner {
            background-color: $color-grey;
            overflow: hidden;
            transform: translate(0, 2em);

            @supports (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
                clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
            }

            @supports not (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
                opacity: 0;
            }
        }

        .o-asset__img {
            transform: scale(1.2);
            transform-origin: 50% 0;
        }

        &.is-visible.is-loaded {

            .o-asset__inner {
                clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                transform: translate(0);
                transition:
                    clip-path var(--reveal-duration) $in-out-circ var(--reveal-delay),
                    transform var(--reveal-duration) $in-out-circ var(--reveal-delay);

                @supports (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
                    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                    transition:
                        clip-path var(--reveal-duration) $in-out-circ var(--reveal-delay),
                        transform var(--reveal-duration) $in-out-circ var(--reveal-delay);
                }

                @supports not (clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)) {
                    opacity: 1;
                    transition:
                        opacity var(--reveal-duration) $in-circ var(--reveal-delay),
                        transform var(--reveal-duration) $in-out-circ var(--reveal-delay);
                }
            }

            .o-asset__img {
                transform: scale(1);
                transition: transform var(--reveal-duration) $in-out-circ var(--reveal-delay);
            }
        }
    }

    &.is-hidden {

        .o-asset__inner {
            transition:
                clip-path calc(var(--reveal-duration)/3) $in-circ calc(var(--reveal-delay)/3),
                transform calc(var(--reveal-duration)/3) $in-circ calc(var(--reveal-delay)/3);
        }

        .o-asset__img {
            transition: transform calc(var(--reveal-duration)/3) $in-circ calc(var(--reveal-delay)/3);
        }
    }

    &.is-zooming {

        .o-asset__btn.-zoom-in {
            transform: scale(0);
            transition: transform .2s $in-circ 0s;
        }

        .o-zoom {
            left: 0;
            pointer-events: initial;
            transition: left 0s .0s;
        }

        .o-zoom__btn {
            transform: scale(1);
            transition: transform .4s $out-circ .6s;
        }

        .o-zoom__watermark {
            opacity: .15;
            transition: opacity .2s ease-out .6s;
        }
    }

    &.-contain {

        &.-portrait {
            display: flex;

            .o-asset__inner {
                width: calc(var(--ratio) * 100%);
                padding-top: 100%;
            }
        }
    }

    &.-grayscale {
        filter: grayscale(1);
    }
}

.o-asset__inner {
    width: 100%;
    height: 0;
    padding-top: calc(1/var(--ratio) * 100%);
    overflow: hidden;
}

.o-asset__img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    // height: auto;
    overflow: hidden;

    opacity: 0;

    img {
        @include img;
    }
}


.o-asset__btn {
    position: absolute;
    top: 1em;
    right: 1em;

    &.-zoom-in {
        transition: transform .4s $out-circ .6s;
    }
}


/*=============================
=            Hover            =
=============================*/


.o-hover {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: .75em;
    color: $color-dark;
    background-color: $color-light;
    transform: scale(1.1);

    .l-wrapper.is-white & {
        background-color: $color-beige;
    }

    @supports (clip-path: polygon(0 0, 0 0)) {
        clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
        transition: clip-path .8s $out-expo, transform .8s $out-expo;
    }

    @supports not (clip-path: polygon(0 0, 0 0)) {
        opacity: 0;
        transition: opacity .2s ease-out, transform .8s $out-expo;
    }
}

.o-hover__tags {
    margin-bottom: auto !important;
}

.o-hover__title {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}



/*==================================
=            Zoom image            =
==================================*/


.o-zoom {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 200%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    transition: left 0s .6s;
}

.o-zoom__picture {
    position: static;
}

.o-zoom__img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform-origin: 0 0;
}

.o-zoom__btn {
    transform: scale(0);
    transition: transform .2s $in-circ 0s;
}

.o-zoom__watermark {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .2s ease-in 0s;
}

</style>
