<template>
    <article class="l-heading">

        <btn-return
            v-if="previous !== null"
            class="l-header__return"
            :default="previous"
        />

        <div class="grid">
            <div class="grid__row">
                <asset
                    v-if="Array.isArray(assets)"
                    v-for="(asset, i) in assets"
                    :key="`heading-asset-${i}`"
                    v-bind="asset"
                    tag="span"
                    crop="grid"
                    :contain="true"
                    :grayscale="true"
                    :class="`l-heading__photo -i${i+1}`"
                    v-view.reveal.parallax
                />
                <asset
                    v-else-if="assets && Object.keys(assets).length && assets.constructor === Object"
                    v-bind="assets"
                    tag="span"
                    crop="grid"
                    :contain="true"
                    :grayscale="true"
                    :class="`l-heading__photo -cover`"
                    v-view.reveal.parallax
                />
                <div class="grid__col--22 grid__col--18@md u-offset-1">
                    <header class="l-heading__inner">
                        <anim-text
                            v-if="title"
                            ref="title"
                            :text="title"
                            type="chars"
                            :show="true"
                            class="l-heading__title | t-t1"
                        />
                        <div
                            v-if="(heading !== null && heading != '') || (intro !== null && intro != '')"
                            class="l-heading__header"
                        >
                            <anim-text
                                v-if="heading !== null && heading != ''"
                                ref="heading"
                                :text="heading"
                                type="words"
                                :show="true"
                                tag="h2"
                                class="l-heading__sub | t-t5"
                            />
                            <anim-text
                                v-if="intro !== null && intro != ''"
                                ref="intro"
                                :text="intro"
                                type="words"
                                :show="true"
                                tag="h3"
                                class="l-heading__intro | t-big"
                            />
                        </div>
                        <anim-text
                            v-if="description !== null && description != ''"
                            ref="desc"
                            :text="description"
                            type="html"
                            :show="true"
                            class="l-heading__desc"
                        />
                    </header>
                </div>
            </div>
        </div>
    </article>
</template>

<script>

import Asset from 'objects/Asset';
import AnimText from 'objects/AnimText';
import BtnReturn from 'objects/BtnReturn';

import { EventBus } from 'src/event-bus';

export default {
    name: 'Heading',
    components: {
        Asset,
        AnimText,
        BtnReturn,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        heading: {
            type: String,
            default: null,
        },
        intro: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        assets: {
            type: Object|Array,
            default: null
        },
        previous: null,
    },
    created() {

        // Pause reveals
        EventBus.$emit('v-view-pause')
    },
    mounted() {

        // Unpause reveals after heading animation is finished
        setTimeout(() => {
            EventBus.$emit('v-view-unpause')
        }, this.revealEndTime);
    },
    computed: {
        revealEndTime() {
            let time = 0


            if (this.$refs.desc) {
                time = this.$refs.desc.duration.endIn
            } else if (this.$refs.intro) {
                time = this.$refs.intro.duration.endIn
            } else if (this.$refs.heading) {
                time = this.$refs.heading.duration.endIn
            } else if (this.$refs.title) {
                time = this.$refs.title.duration.endIn
            }

            // Return time in ms
            return time * 1000
        },
    },
};

</script>

<style lang="scss">

.l-heading {
    padding-top: $header-size;
    padding-bottom: $header-size;
}

.l-header__return {

    @media #{md("sm")} {
        // top: -$header-size;

        & + .grid {
            padding-top: 15vh;
        }
    }
}

.l-heading__inner {

    @media #{md("sm")} {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.l-heading__title {
    --anim-delay: #{$grid-layout-duration-out};

    @media #{md("sm", "max")} {
        margin-bottom: 5vh;
    }

    @media #{md("sm")} {
        order: 2;

        & + .l-heading__header .l-heading__intro {
            margin-bottom: 20vh;
        }

        & ~ .l-heading__desc {
            margin-top: 10vh;
        }
    }

    & + .l-heading__header {

        .l-heading__sub {
            --anim-delay: #{$grid-layout-duration-out + .8s};
        }

        .l-heading__intro {
            --anim-delay: #{$grid-layout-duration-out + 1s};
        }

        & + .l-heading__desc {
            --anim-delay: #{$grid-layout-duration-out + 1.2s};
        }
    }

    & + .l-heading__desc {
        --anim-delay: #{$grid-layout-duration-out + .8s};
    }
}

.l-heading__header {

    @media #{md("sm")} {
        order: 1;
    }
}

.l-heading__sub {
    --anim-delay: #{$grid-layout-duration-out};
    margin-bottom: .5em;

    & + .l-heading__intro {
        left: -.05em;
    }
}

.l-heading__intro {
    --anim-delay: #{$grid-layout-duration-out + .4s};
}

.l-heading__desc {
    --anim-delay: #{$grid-layout-duration-out};

    margin-top: 5vh;
    padding-top: .75em;
    padding-bottom: .75em;

    &:before {
        @include pseudo-el($bg: $color-beige);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    &.is-visible {

        &:before {
            opacity: 1;
            transition: opacity var(--anim-duration-in) ease-in-out var(--anim-delay);
        }
    }

    .is-beige & {
        color: $color-dark;
    }

    @media #{md("xs")} {
        width: 85%;
    }

    @media #{md("sm")} {
        order: 3;
        width: 73%;
    }

    @media #{md("md")} {
        width: 54%;
    }
}

.l-heading__photo {
    --reveal-end: 1;

    position: absolute;
    will-change: transform;

    &.-cover {
        --parallax-y: 7;
        top: 0;
        right: -$header-size;
        width: calc(1/4 * 100% + #{$header-size});
    }

    &.-i1 {
        --parallax-y: -7;
        top: -5vh;
        right: -$header-size;
        width: calc(1/4 * 100% + #{$header-size});
    }

    &.-i2 {
        --parallax-y: -5;
        top: 40%;
        left: calc(1/2 * 100%);
        width: calc(1/3 * 100%);
    }

    &.-i3 {
        --parallax-y: -3;
        bottom: 0;
        left: calc(3/4 * 100%);
        width: calc(1/4 * 100% + var(--grid-gutter));
    }

    @media #{md("sm", "max")} {
        display: none !important;
    }

    @media #{md("lg")} {

        &.-i1,
        &.-cover {
            width: calc(1/4 * 100%);
        }

        &.-i2 {
            top: auto;
            bottom: 0;
        }

        &.-i3 {
            display: none;
        }
    }
}

</style>
