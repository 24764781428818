<template>
    <article class="p-order">

        <btn-return :default="`/photo/${photo.reference}`"/>

        <div class="grid">

            <anim-text
                text="Demande d’achat"
                :reveal="true"
                type="chars"
                class="p-order__title | t-t1"
            />

            <asset
                v-bind="photo"
                crop="detail"
                sizes="75w, (min-width: 768px) 35w"
                class="p-order__asset"
            />

            <div class="grid__row | u-jc-center">
                <div class="grid__col--24 grid__col--16@sm grid__col--12@lg grid__col--10@xl | u-no-gutter">

                    <form
                        v-if="!success"
                        action="/"
                        class="c-form | grid__row"
                        @submit.prevent="submit"
                    >
                        <div v-if="error" class="c-form__error">
                            <p>
                                Une erreur est survenue au moment de l'envoi, veuillez vérifier tous les champs ou essayez de nouveau. Si l’erreur persiste, n’hésitez pas à communiquer avec le musée.
                            </p>
                        </div>

                        <div
                            v-for="input in inputs"
                            :key="`input-${input.name}`"
                            class="c-form__el | grid__col--24"
                            :class="{ 'grid__col--12@xs' : input.half }"
                        >
                            <app-select
                                v-if="input.options"
                                v-model="input.value"
                                :options="input.options"
                                :placeholder="input.label"
                                as="label:value"
                            />
                            <app-input
                                v-else
                                v-model="input.value"
                                :label="input.label"
                                :modifier="input.modifier"
                                :type="input.type"
                                :autocomplete="input.autocomplete"
                                :required="input.required"
                                :disabled="input.disabled ? input.disabled : false"
                                :defaultValue="defaultValue(input.name)"
                            />

                        </div>

                        <div class="c-form__el -submit | grid__col--24">
                            <btn
                                label="Envoyer"
                                size="lg"
                                iconAfter="arrow-right"
                                type="submit"
                            />
                        </div>
                    </form>

                    <div
                        v-else
                        class="o-success"
                    >
                        <h3 class="o-success__title | t-t3">Demande envoyée</h3>
                        <div class="o-success__desc | t-cms">
                            <p>Votre demande d'achat a bien été envoyée, nous vous recontacterons rapidement.</p>
                            <p>Retourner à la <router-link to="/" title="Accueil">page d'accueil</router-link></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </article>
</template>

<script>

import Asset from 'objects/Asset';
import AnimText from 'objects/AnimText';
import AppInput from 'objects/AppInput';
import AppSelect from 'objects/AppSelect';
import BtnReturn from 'objects/BtnReturn';
import Btn from 'components/Btn';

export default {
    name: 'Photo',
    components: {
        Asset,
        AnimText,
        AppInput,
        AppSelect,
        BtnReturn,
        Btn,
    },
    data: () => ({
        inputs: [
            {
                label: 'Nom complet',
                name: 'fromName',
                value: '',
                modifier: 'text',
                type: 'text',
                required: true,
                autocomplete: 'name',
                half: false,
            },
            {
                label: 'Téléphone',
                name: 'message.phone',
                value: '',
                modifier: 'text',
                type: 'phone',
                required: true,
                autocomplete: 'tel',
                half: true,
            },
            {
                label: 'Courriel',
                name: 'fromEmail',
                value: '',
                modifier: 'text',
                type: 'email',
                disabled: false,
                defaultValue: false,
                required: true,
                autocomplete: 'email',
                half: true,
            },
            {
                label: 'Adresse postale complète',
                name: 'message.address',
                value: '',
                modifier: 'text',
                type: 'text',
                required: true,
                autocomplete: 'street-address',
                half: false,
            },
            {
                label: 'Format',
                name: 'message.size',
                value: '',
                options: [
                    {
                        label: 'Impression',
                        value: 'impression',
                    },
                    {
                        label: 'Numérique',
                        value: 'numerique',
                    },
                    {
                        label: 'Demande de re-scan ',
                        value: 're-scan',
                    },
                ],
                half: true,
            },
            {
                label: 'Reference',
                name: 'message.reference',
                value: '',
                modifier: 'text',
                type: 'text',
                disabled: true,
                required: true,
                half: true,
            }
        ],
        order: {
            message: {}
        },
        error: false,
        success: false,
        pending: false
    }),
    metaInfo() {
        return {
            title: 'Demande d’achat - Cyberphotos',
        }
    },
    created() {
        // Add Craft CSRF token to order
        this.order[this.csrfName] = this.csrfToken
    },
    computed: {
        photo() {
            return this.$store.getters['global/getCurrentPage']
        },
        csrfName: () => window.csrfTokenName,
        csrfToken: () => window.csrfTokenValue,
        user() {
            return this.$store.getters['global/getUser']
        },
    },
    methods: {
        defaultValue(inputName) {
            if (inputName === 'message.reference') {
                return this.photo.reference !== null ? this.photo.reference : this.photo.title
            } else if (!this.user) {
                return false
            } else {
                return this.user[inputName]
            }
        },
        submit() {

            if ( this.pending ) {
                return
            }

            // Submit form if no errors
            let inputName
            const user = {}

            // reset
            this.success = false
            this.error = false
            this.pending = true

            this.inputs.forEach((input, i) => {
                inputName = input.name

                if ( input.value === '' && input.required) {
                    this.error = true
                    this.pending = false
                    this.inputs[i].error = true
                }

                // Set user
                if (inputName !== 'message.reference') {
                    user[inputName] = input.value
                }

                if (inputName.startsWith('message.')) {
                    inputName = inputName.replace('message.', '')
                    this.order.message[inputName] = input.value
                } else {
                    this.order[inputName] = input.value
                }
            })

            if ( !this.error ) {
                this.$store.dispatch('global/setUser', user)

                fetch('/actions/contact-form/send', {
                        method: 'POST',
                        headers : {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(this.order)
                    })
                    .then(r => r.json())
                    .then(data => {

                        if (data && data.success) {
                            this.success = true
                            this.pending = false
                            this.error = false
                        } else {
                            this.success = false
                            this.pending = false
                            this.error = true
                        }
                    })
                    .catch(error => {
                        console.log(error) // eslint-disable-line
                    })
            }

        }
    }
};

</script>

<style lang="scss">

// .p-order {}

.p-order__title {
    justify-content: center;

    @media #{md("sm", "max")} {
        margin-top: 10vh;
    }

    @media #{md("sm")} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

.p-order__asset {
    width: 75vw;
    padding-top: 10vh;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5vh;
    @media #{md("sm")} {
        width: 35vw;
    }
}


/*----------  Success  ----------*/

.o-success {
    margin-top: 5vh;
    margin-bottom: 5vh;
    color: $color-dark;
    text-align: center;
}

.o-success__title {
    margin-bottom: .75em;
}

.o-success__desc {}


</style>
