import Vue from 'vue'
import { gsap } from 'gsap'

import { EventBus } from 'src/event-bus';

import isEqual from 'lodash/isEqual';

const roundPercent = Symbol('roundPercent');
const whichTransitionEvent = Symbol('whichTransitionEvent');

const viewClass = new class View {

    constructor() {
        // console.log(`${this.constructor.name}:init`)

        this.class = {
            reveal: 'js-reveal',
            revealVisible: 'is-visible',
        }

        // Correct transitionend event name
        this.transitionEnd = this[whichTransitionEvent]() ? this[whichTransitionEvent]() : 'transitionsEnd';

        this.pos, this.lastPos = {};

        this.items = {}
        this.activeItems = []

        this.paused = false

        // Define observer
        let $target, id
        this.observer = new IntersectionObserver($observables => {
            this.lastPos = {}
            $observables.forEach(($observable) => {
                $target = $observable.target
                id = $target.id
                if ($observable.isIntersecting) {
                    this.activeItems.push(id)
                } else {
                    this.activeItems = this.activeItems.filter(i => i !== id)
                    if (this.items[id] && this.items[id].revealInfinite) {
                        this.items[id].reveal = true
                        $target.classList.add(this.class.reveal)
                    }
                }
            })
        });

        // Window events
        window.addEventListener('load', () => {

            // Scroll in page
            this.scroll = {
                x: window.pageYOffset,
                y: window.pageXOffset
            }

            // Set sizes
            this.setSizes()

            // RAF Loop
            window.requestAnimationFrame(this.loop.bind(this))
        })

        window.addEventListener('resize', this.setSizes.bind(this))
    }

    setSizes() {
        //console.log(`${this.constructor.name}:setSizes`)

        this.W = {
            w: window.innerWidth,
            h: window.innerHeight,
        }

        this.doc = {
            h: document.documentElement.scrollHeight,
            w: document.documentElement.scrollWidth
        }

        /*

        // Loop through items and update css values
        const ids = Object.keys(this.items)
        let i, $el, styles

        console.log(this.items, ids)

        for (let id of ids) {
            i = this.items[id]

            if (!i.reveal && i.parallax !== false) {
                return
            }


            $el = document.getElementById(id)
            styles = getComputedStyle($el)
            console.log($el, styles)

            if (i.reveal) {
                propertyValue = styles.getPropertyValue('--reveal-end')
                i.end = propertyValue ? propertyValue : false
            }

            if (i.parallax !== false) {
                propertyValue = styles.getPropertyValue('--parallax-duration')
                i.parallax.duration = propertyValue ? propertyValue : .2
                propertyValue = styles.getPropertyValue('--parallax-x')
                i.parallax.x = propertyValue ? propertyValue : 0
                propertyValue = styles.getPropertyValue('--parallax-y')
                i.parallax.y = propertyValue ? propertyValue : 0
            }
        }

        console.log('new items: ', this.items)

        */
    }

    observeItem(id, item) {
        this.items[id] = item
        this.observer.observe(this.items[id].element)
    }

    unobserveItem(id) {

        if (!this.items[id])
            return

        this.observer.unobserve(this.items[id].element)
        delete this.items[id]
        this.activeItems = this.activeItems.filter(i => i !== id)
    }

    loop() {

        if(this.paused) {
            requestAnimationFrame(this.loop.bind(this));
            return;
        }

        this.pos = {
            top: window.pageYOffset,
            right: window.pageXOffset + this.W.w,
            bottom: window.pageYOffset + this.W.h,
            left: window.pageXOffset,
        }

        if(isEqual(this.pos, this.lastPos)) {
            requestAnimationFrame(this.loop.bind(this));
            return;
        } else {
            this.lastPos = this.pos;
        }

        let i, inType;
        this.activeItems.forEach(id => {
            i = this.items[id]

            inType = this.getInType(i)

            // Function handler
            if (i.handler) {
                i.handler({
                    // type: eventType,
                    percent: {
                        top: inType.percent.top,
                        bottom: inType.percent.bottom,
                        left: inType.percent.left,
                        right: inType.percent.right,
                        center: {
                            x: inType.percent.center.x,
                            y: inType.percent.center.y,
                        },
                        scroll: {
                            x: this[roundPercent](this.pos.top / (this.doc.h - this.W.h)),
                            y: this[roundPercent](this.pos.left / (this.doc.w - this.W.w))
                        }
                    },
                    rect: inType.rect,
                    scroll: {
                        x: this.pos.top - this.scroll.x,
                        y: this.pos.left - this.scroll.y,
                    },
                    target: i.element
                })
            }

            // Reveal
            if (i.reveal) {
                // console.log('reveal',  i)

                this.items[i.id].reveal = false

                i.element.classList.add(this.class.revealVisible)

                if(i.end) {
                    let item = i
                    setTimeout(() => {
                        this.revealEnd(item)
                    }, item.end * 1000);
                } else {
                    i.element.addEventListener(this.transitionEnd, this.revealEnd(i));
                }
            }

            // Parallax
            if (i.parallax !== false) {

                gsap.to(i.element, {
                    duration: i.parallax.duration,
                    x: (1 - inType.percent.top) * i.parallax.x * 10,
                    y: (1 - inType.percent.top) * i.parallax.y * 10
                })
            }
        })

        // Update scroll values
        this.scroll = {
            x: this.pos.top,
            y: this.pos.left,
        };


        requestAnimationFrame(this.loop.bind(this))
    }

    revealEnd(i) {
        // console.log(`${this.constructor.name}:revealEnd`, i)

        i.element.classList.remove(this.class.reveal)
        i.element.classList.remove(this.class.revealVisible)
        i.element.removeEventListener(this.transitionEnd, this.revealEnd)
    }

    getInType(i) {
        // console.log(`${this.constructor.name}:getInType`, i)

        let rect = i.element.getBoundingClientRect();
        let elementTop = rect.top + this.pos.top;
        let elementLeft = rect.left + this.pos.left;
        let elementBottom = elementTop + rect.height;
        let topIn = elementTop > this.pos.top && elementTop < this.pos.bottom;
        let bottomIn = elementBottom > this.pos.top && elementBottom < this.pos.bottom;
        let percentInView = topIn || bottomIn ? ((bottomIn ? elementBottom : this.pos.bottom) - (topIn ? elementTop : this.pos.top)) / rect.height : 0;
        let centerPercentY = (elementTop - this.pos.top + rect.height / 2) / this.W.h;
        let centerPercentX = (elementLeft - this.pos.left + rect.width / 2) / this.W.w;

        let topPercent = this[roundPercent](rect.top + rect.height) / (this.W.h + rect.height);
        let bottomPercent = 1 - topPercent;
        let leftPercent = this[roundPercent](rect.left + rect.width) / (this.W.w + rect.width);
        let rightPercent = 1 - leftPercent;

        return {
            percent: {
                inView: this[roundPercent](percentInView),
                top: topPercent,
                bottom: bottomPercent,
                left: leftPercent,
                right: rightPercent,
                center: {
                    x: this[roundPercent](centerPercentX),
                    y: this[roundPercent](centerPercentY),
                },
            },
            rect: rect
        }
    }

    /**
    * Round percentage to a thousandth
    * @return {number} The rounded number
    */
    [roundPercent](v) {
        return (v * 1000 | 0) / 1000
    }

    /**
    * Use the correct `transitionend` event
    * @return {string} The prefixed event name
    */
    [whichTransitionEvent]() {
        const el = document.createElement('div');
        const transitions = {
            'OTransition':'oTransitionEnd',
            'MozTransition':'transitionend',
            'WebkitTransition':'webkitTransitionEnd',
            'transition':'transitionend',
        };

        for(let t in transitions){
            if( el.style[t] !== undefined ){
                return transitions[t];
            }
        }
    }
}

let itemIndex = 0
export const view = Vue.directive('view', {
    inserted: function($el, bind) {

        if(typeof bind.value === 'boolean' && !bind.value) {
            return
        }

        let id = $el.id || ('view-id-' + itemIndex++)
        let item = viewClass.items[id] || {
            id,
            element: $el,
            handler: false,
            reveal: false,
            parallax: false,
            rect: {},
        }

        /*
        if (bind.modifiers.reveal === true) {
            item.reveal = true
        }
        if (bind.modifiers.parallax === true) {
            item.parallax = {}
        }
        */

        const styles = getComputedStyle($el);
        let propertyValue

        // Detect modifiers
        if (bind.modifiers.reveal === true) {
            item.reveal = true

            propertyValue = styles.getPropertyValue('--reveal-infinite').replace(' ', '')
            item.revealInfinite = propertyValue === '1' ? true : false

            propertyValue = styles.getPropertyValue('--reveal-end')
            if (propertyValue) {
                item.end = propertyValue
            }
            item.element.classList.add(viewClass.class.reveal)
        }

        if (bind.modifiers.parallax === true) {
            item.parallax = {}

            propertyValue = styles.getPropertyValue('--parallax-duration')
            item.parallax.duration = propertyValue ? propertyValue : .2
            propertyValue = styles.getPropertyValue('--parallax-x')
            item.parallax.x = propertyValue ? propertyValue : 0
            propertyValue = styles.getPropertyValue('--parallax-y')
            item.parallax.y = propertyValue ? propertyValue : 0
        }

        // Detect values
        if(typeof bind.value === 'boolean' && !bind.value) {
            return
        } else if(typeof bind.value === 'number' && item.reveal) {
            item.end = bind.value
        } else if(typeof bind.value === 'function') {
            item.handler = bind.value
        } else if(typeof bind.value === 'object') {
            if(typeof bind.value.handler === 'function') {
                item.handler = bind.value.handler
            }
            if(typeof bind.value.end === 'number') {
                item.end = bind.value.end
            }
            if(typeof bind.value.duration === 'number') {
                item.parallax.duration = bind.value.duration
            }
            if(typeof bind.value.x === 'number') {
                item.parallax.x = bind.value.x
            }
            if(typeof bind.value.y === 'number') {
                item.parallax.y = bind.value.y
            }
        }

        $el.id = id

        viewClass.observeItem(id, item)
    },
    unbind: function($el, bind) {

        if(typeof bind.value === 'boolean' && !bind.value) {
            return
        }

        viewClass.unobserveItem($el.id)
        // delete view.items[$el.id]
    }
})


EventBus.$on('v-view-pause', () => {
    viewClass.paused = true
})

EventBus.$on('v-view-unpause', () => {
    viewClass.paused = false
})
