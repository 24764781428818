<template>
    <component
        v-if="text"
        :is="animHtml ? 'div' : tag"
        :class="className"
        :style="styles"
        v-view="reveal ? inView : false"
    >
        <div
            v-if="animHtml"
            class="o-at__html | t-cms"
            v-html="text"
        ></div>
        <span
            v-else
            v-for="(word, i) in words"
            :key="`word-${i}`"
            class="o-at__w"
        >
            <span
                v-if="animWords"
                class="o-at__w-inner"
                :style="`--anim-offset: ${$options.count++}`"
                v-html="word"
            />
            <span
                v-else
                v-for="(char, j) in word.split('')"
                :key="`char-${j}`"
                class="o-at__c"
                :style="`--anim-offset: ${$options.count++}`"
                v-html="char"
            ></span>
        </span>
    </component>
</template>

<script>

// import { gsap, SplitText } from 'gsap/all'
// gsap.registerPlugin(SplitText);

export default {
    name: 'AnimText',
    count: 1,
    props: {
        tag: {
            type: String,
            default: 'h1',
        },
        text: {
            type: String,
            default: null,
        },
        reveal: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'chars', // chars|words|html
        },
        hover: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isVisible: false,
        duration: {},
    }),
    created() {
        this.animChars = this.type === 'chars'
        this.animWords = this.type === 'words'
        this.animHtml = this.type === 'html'
    },
    mounted() {

        this.duration = {
            delay: Number(this.computedStyles.getPropertyValue('--anim-delay').slice(0, -1).replace(' ', '')),
            in: Number(this.computedStyles.getPropertyValue('--anim-duration-in').slice(0, -1).replace(' ', '')),
            out: Number(this.computedStyles.getPropertyValue('--anim-duration-out').slice(0, -1).replace(' ', '')),
        }

        this.duration.endIn = this.duration.delay + this.duration.in
        this.duration.endOut = this.duration.delay + this.duration.out

        if(this.show) {
            this.isVisible = true
        }

    },
    computed: {
        words() {
            let words = this.text.split(' ')
            return words
        },
        textLength() {
            return this.text.replace(' ', '').split('').length
        },
        className() {
            let className = `o-at -${this.type}`

            if (this.hover) {
                className += ' -hover'
            }

            if (this.isVisible) {
                className += ' is-visible'
            }

            return className;
        },
        styles() {

            if (this.animChars) {
                return `--anim-chars: ${this.textLength}; --anim-words: ${this.words.length};`
            } else if(this.animWords) {
                return `--anim-words: ${this.words.length};`
            } else {
                return false
            }
        },
        computedStyles() {

            if (!this.$el)
                return

            return window.getComputedStyle(this.$el)
        }
    },
    methods: {
        inView() {

            // Return if revealed already
            if(this.isVisible)
                return

            this.isVisible = true
        },
        animate(visible=true) {
            this.isVisible = visible
        }
    },
    watch: {
        isVisible() {
            this.$options.count = 1
        },
    }
};

</script>

<style lang="scss">


/*==============================
=            Styles            =
==============================*/


.o-at {
    --anim-delay: 0s;
    --anim-duration-in: .8s;
    --anim-duration-out: .4s;
    --anim-offset: 0;

    display: flex;
    flex-wrap: wrap;
    margin-right: -.15em;
    margin-left: -.15em;

    &.-chars {
        @include at-chars-out;
    }

    &.-html {
        @include at-html-out;
    }

    &.-words {
        --anim-duration-in: .4s;
    }

    &.-hover {
        transition: color .4s ease-out;

        @supports (clip-path: polygon(.15em 0, calc(100% - .15em) 0, calc(100% - .15em) 100%, .15em 100%)) {
            clip-path: polygon(.15em 0, calc(100% - .15em) 0, calc(100% - .15em) 100%, .15em 100%);
        }

        .o-at__w {
            --hover-ease: linear;

            overflow: visible;

            &:after {
                @include pseudo-el($width: calc(100% + .15em * 2), $height: 1px, $bg: currentColor);
                position: absolute;
                top: 1.15em;
                left: -.15em;
                transform: scale(0, 1);
                transform-origin: 100% 50%;
                transition: transform var(--hover-duration, 0) var(--hover-ease) calc(var(--hover-delay) + var(--hover-extra-delay, 0s));
            }

            @for $i from 1 through 10 {

                &:nth-child(#{$i}) {
                    --hover-duration: calc(.6s/var(--anim-words));
                    --hover-delay: calc(#{$i - 1}/var(--anim-words) * .6s);
                }

                &:first-child,
                &:last-child {
                    --hover-ease: #{$in-out-cubic};
                }
            }
        }

        &:hover {
            color: $color-dark;

            .o-at__w:after {
                transform: scale(1);
                transform-origin: 0 50%;
            }
        }
    }

    &.is-visible {

        // Words animation
        &.-words .o-at__w-inner {
            animation:
                at-word-in-down
                calc(var(--anim-duration-in) - var(--anim-duration-in) * (var(--anim-offset) / var(--anim-words)) / 4)
                ease-out
                calc(var(--anim-duration-in) * (var(--anim-offset) / var(--anim-words)) / 2 + var(--anim-delay))
                forwards;
        }

        &.-chars {
            @include at-chars-in;
        }

        &.-html {
            @include at-html-in;
        }
    }
}

.o-at__w {
    display: inline-flex;
    margin-right: .15em;
    margin-left: .15em;
    overflow: hidden;
}

.o-at__w-inner {
    display: block;
    transform: translate(0, 100%);
    will-change: transform;
}

// .o-at__l {
//     overflow: hidden;
// }

// .o-at__w {
//     transform: translate(0, 100%);
//     will-change: transform;
// }

.o-at__c {
    // display: block;
    background-image: linear-gradient(to bottom, transparent, transparent 20%, var(--color-text, currentColor) 80%, var(--color-text, currentColor) 100%);
    background-size: 100% 500%;
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;

    transform-origin: 50% 100%;
    will-change: transform, background;
}



/*==================================
=            Animations            =
==================================*/


@keyframes at-word-in-down {
    0% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0);
    }
}


@keyframes at-word-in-up {
    0% {
        transform: translate(0, -100%);
    }
    100% {
        transform: translate(0);
    }
}


@keyframes at-word-out-down {
    0% {
        transform: translate(0);
    }
    100% {
        transform: translate(0, 100%);
    }
}

@keyframes at-word-out-up {
    0% {
        transform: translate(0);
    }
    100% {
        transform: translate(0, -100%);
    }
}


</style>
