<template>
    <div
        id="app"
        :style="`--vh: ${W.h/100}px;`"
    >
        <app-base />
        <app-cursor v-if="!isTouch"/>
    </div>
</template>

<script>

import AppCursor from 'objects/AppCursor';
import Variables from 'src/mixins/variables';

import AppBase from 'layout/AppBase';

import 'assets/scss/styles.scss';

import { scrollstart, scrollend } from '@lucasvallenet/js-custom-events'

scrollstart()
scrollend()

export default {
    name: 'app',
    components: {
        AppBase,
        AppCursor,
    },
    mixins: [ Variables ],
    metaInfo() {
        return {
            titleTemplate: `%s`
        }
    },
    created() {
        const doc = document.documentElement.classList
        // Browser and OS classes
        doc.add(`is-${this.browser.os.replace(' ', '-').toLowerCase()}`)
        doc.add(`is-${this.browser.name}`)

        if(this.isTouch) {
            doc.add('is-touch')
        }
    }
}
</script>

<style lang="scss">
</style>
