<template>
    <div :class="className">
        <div class="grid">

            <btn-circle
                tag="router-link"
                :href="previous"
                icon="cross"
                label="Fermer"
                size="lg"
                color="blue"
                class="p-search__return"
                :hidden="!isLoaded"
                @click.native="close"
            />

            <anim-text
                text="Recherche"
                :show="true"
                type="chars"
                class="p-search__title | t-t1"
            />

            <div class="grid__row | u-jc-center">
                <div class="grid__col--24 grid__col--16@sm grid__col--12@lg grid__col--10@xl | u-no-gutter">
                    <form
                        action="/"
                        class="c-form | grid__row"
                        @submit.prevent
                    >
                        <div class="c-form__el | grid__col--24">
                            <app-input
                                ref="inputText"
                                v-model="inputText"
                                :defaultValue="inputText"
                                :hasReset="true"
                            >
                                <span class="o-placeholder">
                                    <span class="o-placeholder__inner">
                                        Chercher par
                                        <span class="o-placeholder__item">mots-clés</span>
                                        <span class="o-placeholder__item">fond</span>
                                        <span class="o-placeholder__item">municipalité</span>
                                        <span class="o-placeholder__item">numéro d'accession</span>
                                    </span>
                                </span>
                            </app-input>
                        </div>
                        <div class="c-form__el | grid__col--24">
                            <app-input-range
                                ref="inputDate"
                                v-model="inputDate"
                                label="Année"
                                :start="dateStart"
                                :end="dateEnd"
                                :defaultValue="inputDate"
                            />
                        </div>

                        <app-select
                            ref="inputFund"
                            v-model="inputFund"
                            :defaultValue="inputFund"
                            placeholder="Fonds Photographique"
                            :options="funds"
                            :all="true"
                            as="title:id"
                            class="c-form__el | grid__col--24 grid__col--12@sm"
                        />

                        <app-select
                            ref="inputRegion"
                            v-model="inputRegion"
                            :defaultValue="inputRegion"
                            placeholder="Région"
                            :options="regions"
                            :all="true"
                            as="title:id"
                            class="c-form__el | grid__col--24 grid__col--12@sm"
                        />

                        <div class="c-form__el -submit | grid__col--24">
                            <btn
                                label="Rechercher"
                                size="lg"
                                @click.native="submit"
                            >
                                <template #after>
                                    <btn-circle
                                        display="border"
                                        class="c-btn__icon"
                                    >
                                        <div
                                            ref="loader"
                                            class="p-search__loader"
                                        ></div>
                                    </btn-circle>
                                </template>
                            </btn>
                        </div>

                        <div
                            v-if="searchIndex > 0 && !isLoading"
                            class="c-form__el -button | grid__col--24"
                        >
                            <btn
                                label="Réinitialiser"
                                modifier="secondary"
                                @click.native="reset"
                            />
                        </div>
                    </form>

                </div>
            </div>
            <span
                v-if="!isLoading && totalResults !== -1"
                id="results"
                class="p-search__count"
            >
                <span class="t-t4">{{ totalResults }} résultats</span>
                <btn-circle
                    tag="button"
                    icon="arrow-down"
                    label="Voir"
                    color="blue"
                    @click.native="scrollToResults()"
                />
            </span>
        </div>

        <grid-assets
            v-if="searchIndex > 0"
            :key="searchIndex"
            :searchQuery="query"
            @loading="loading => isLoading = loading && totalResults < 0"
        />

    </div>
</template>

<script>

import AnimText from 'objects/AnimText';
import AppInput from 'objects/AppInput';
import AppSelect from 'objects/AppSelect';
import AppInputRange from 'objects/AppInputRange';
import GridAssets from 'objects/GridAssets';
import Btn from 'components/Btn';
import BtnCircle from 'objects/BtnCircle';

import lottie from 'lottie-web'
import { mapState } from 'vuex'
import { gsap, ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'Directory',
    components: {
        AnimText,
        AppInput,
        AppSelect,
        AppInputRange,
        GridAssets,
        Btn,
        BtnCircle,
    },
    props: {
        page: {},
    },
    metaInfo() {
        return {
            title: 'Recherche - Cyberphotos',
        }
    },
    data: () => ({
        inputText: '',
        inputFund: false,
        inputRegion: false,
        inputDate: [1850, 1990],
        inputqueryRelatedTo: [],
        query: '',
        previousQuery: null,
        searchIndex: 0,
        isLoaded: false,
        isLoading: false,
    }),
    created() {

        // Set default date range
        this.inputDate = [this.dateStart, this.dateEnd]

        if(this.storedQuery) {
            this.inputText = this.storedQuery.inputText
            this.inputFund = this.storedQuery.inputFund
            this.inputRegion = this.storedQuery.inputRegion
            this.inputDate = this.storedQuery.inputDate
            this.query = this.storedQuery.query
            this.submit()
        }
    },
    mounted() {
        this.setDefaultQuery()
        this.isLoaded = true

        // Bind enter key
        document.addEventListener('keyup', this.keyUp)

        this.loaderAnimation = lottie.loadAnimation({
            container: this.$refs.loader,
            renderer: 'svg',
            autoplay: false,
            loop: true,
            animationData: require(`assets/animations/search-loader.json`),
        });

        // Stop animation on loader end
        this.loaderAnimation.addEventListener('loopComplete', this.loadEnd = () => {
            if(!this.isLoading) {
                this.loaderAnimation.goToAndStop(this.loaderAnimation.totalFrames, true)
            }
        })
    },
    computed: {
        ...mapState({
            funds: state => state.categories.funds,
            regions: state => state.categories.regions,
            tags: state => state.categories.tags,
            dateStart: state => state.photos.dateStart,
            dateEnd: state => state.photos.dateEnd,
            storedQuery: state => state.search.query,
            totalResults: state => state.search.count,
        }),
        className() {
            let classname = 'p-search'

            if(this.isLoaded) {
                classname += ' is-loaded'
            }

            if(this.isLoading) {
                classname += ' is-loading'
            }

            return classname
        },
        routeQuery() {
            return this.$route.query
        },
        queryText() {
            return this.inputText !== '' ? `search: "*${this.inputText}*", ` : ''
        },
        queryRelatedTo() {
            let relations = []

            if (this.inputFund) {
                relations.push(this.inputFund)
            }

            if (this.inputRegion) {
                relations.push(this.inputRegion)
            }

            if (relations.length === 0) {
                return ''
            }

            return `relatedTo: [${relations}], `
        },

        queryDate() {

            return `
                date: ">= ${this.inputDate[0]}",
                dateEnd: "<= ${this.inputDate[1]}",
            `
        },
        previous() {
            const prev = this.$store.getters['global/getPreviousRoutePath']
            return prev ? prev : '/'
        },
    },
    methods: {
        setDefaultQuery() {
            const tag = this.routeQuery.tags
            if (typeof tag !== 'undefined') {
                this.inputText = tag
                this.$refs.inputText.setValue(tag)
            }

            const fund = this.routeQuery.funds
            if (typeof fund !== 'undefined') {
                this.inputFund = fund
                this.$refs.inputFund.setOption(fund)
            }

            const region = this.routeQuery.regions
            if (typeof region !== 'undefined') {
                this.inputRegion = fund
                this.$refs.inputRegion.setOption(region)
            }

        },
        setDefaultFund() {
            const fund = this.routeQuery.funds
            if (typeof fund !== 'undefined') {
                this.$refs.inputFund.setOption(fund)
            }
        },
        submit() {
            // Concatenate queries
            this.query = this.queryText + this.queryRelatedTo + this.queryDate

            // Return if previous and current queries are the same
            if (this.previousQuery === this.query) {
                return
            }

            // Increment search index to generate new key and update grid-assets
            this.searchIndex++

            this.previousQuery = this.query

            this.$store.dispatch('search/updateQuery', {
                inputText: this.inputText,
                inputFund: this.inputFund,
                inputRegion: this.inputRegion,
                inputDate: this.inputDate,
                query: this.query
            })

            this.$store.dispatch('search/fetchResultCount', this.query)
        },
        reset() {
            this.searchIndex = 0

            this.$refs.inputText.reset()
            this.$refs.inputFund.reset()
            this.$refs.inputRegion.reset()
            this.$refs.inputDate.reset()

            this.$store.dispatch('search/deleteQuery')
            this.$store.dispatch('search/resetResultCount')
        },
        keyUp(e) {
            if(e.code === 'Enter') {
                this.submit()
            }
        },
        close() {
            this.$store.dispatch('search/deleteQuery')
            this.isLoaded = false
        },
        scrollToResults() {

            gsap.to(window, {
                duration: 2,
                ease: 'expo.inOut',
                scrollTo: '#results',
            })
        },
    },
    watch: {
        isLoading(loading) {

            // Load animation start
            if(loading) {
                this.loaderAnimation.playSegments([[0, 30], [30, 60]], true)

            // Load animation end
            } else {
                this.loaderAnimation.playSegments([60, 90])
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyUp)
        this.loaderAnimation.removeEventListener('loopComplete', this.loadEnd)
    }
};

</script>

<style lang="scss">

.p-search {
    min-height: vh(100);

    .c-form {
        margin-bottom: 7vh;
    }

    .c-form__el {
        opacity: 0;
        transform: translate(0, 1em);
        transition: 0s 1s;

        .o-input.-text .o-input__label {
            width: 100%;
        }

        &.-submit .c-btn {
            transition: color .4s ease-out .2s;

            .o-btn-circle {
                color: inherit;
            }
        }
    }

    &.is-loaded {

        .c-form__el {
            opacity: 1;
            transform: translate(0);

            @for $i from 1 through 5 {

                &:nth-child(#{$i}) {
                    $i: $i - 1;
                    transition: opacity .4s ease-out #{1 + $i/12}s, transform .6s $out-circ #{1 + $i/12}s;
                }
            }
        }
    }

    &.is-loading {

        .c-form__el.-submit .c-btn {
            color: $color-grey;
            pointer-events: none;
        }
    }
}

.p-search__title {
    //--anim-delay: #{$grid-layout-duration-out};

    justify-content: center;

    @media #{md("sm", "max")} {
        margin-top: 1em;
    }
}

.p-search__return {
    --transition-delay-visible: .4s;

    @media #{md("sm", 'max')} {
        left: calc(50% - var(--size)/2);
        margin-top: 1em;
    }

    @media #{md("sm")} {
        z-index: 1;
        position: fixed;
        top: var(--grid-gutter);
        right: var(--grid-gutter);
    }
}

.p-search__count {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-dark;
    text-align: center;

    .o-btn-circle {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .o-btn-circle__label {
        margin: 0;
    }
}

.p-search__loader {
    position: absolute;
    top: 20%;
    left: 20%;
    width: calc(100% - 20% * 2);
    height: calc(100% - 20% * 2);
    color: inherit;

    path {

        &[fill] {
            fill: currentColor !important;
        }

        &[stroke] {
            stroke: currentColor !important;
        }
    }
}


/*===================================
=            Placeholder            =
===================================*/

.o-placeholder {
    display: block;
    width: 100%;
    overflow: hidden;
}

.o-placeholder__inner {
    display: block;
    overflow: hidden;
    transition: transform .2s ease-out;

    .is-filled & {
        transform: translate(0, 100%);
    }
}

.o-placeholder__item {

    position: absolute;
    top: 0;
    left: 6.4em;
    display: block;
    white-space: nowrap;
    transform: translate(0, -100%);
    animation: anim-word-loop 10s infinite;

    @for $i from 2 through 4 {
        &:nth-child(#{$i}) {
            animation-delay: #{($i - 1)/4 * 10}s
        }
    }
}

@keyframes anim-word-loop {
    0% {
        transform: translate(0, -100%);
    }
    5% {
        transform: translate(0);
    }
    22.5% {
        transform: translate(0);
    }
    27.5% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0, 100%);
    }
}


</style>
