import { detect } from 'detect-browser';
const browser = detect();

import debounce from 'lodash/debounce';

export default {
    data: () => ({
        W: {
            w: window.innerWidth,
            h: window.innerHeight,
            ratio: window.innerWidth/window.innerHeight,
        },
        browser,
        isTouch: 'ontouchstart' in window,
    }),
    mounted() {

        const set = () => {
            // Define window vars
            this.W = {
                w: window.innerWidth,
                h: window.innerHeight,
            }

            this.W.ratio = this.W.w/this.W.h

            // Check mediaquery for horizontal layout
            if(window.matchMedia(this.horizontalBreakpoint).matches) {
                this.isHorizontal = true
            } else {
                this.isHorizontal = false
            }
        }

        // Set size
        set()

        // Add Window events
        window.addEventListener('resize', this.resizeFunction = debounce(set, 100));
    },
    destroyed() {

        // Remove Window events
        window.removeEventListener('resize', this.resizeFunction);
    }
}
