import { fetchApi } from 'src/graphql/config'
import queries from 'src/graphql/queries'

/*
** Pages
*/

// State
const state = {
    pages: [],
}

// Getters
const getters = {
    getPageById: state => id => state.pages.find(page => page.id === id),
    getPageByHandle: state => section => state.pages.find(page => page.sectionHandle === section),
}

// Actions
const actions = {
    loadPage(store, handle) {

        return new Promise((resolve, reject) => {

            // Search if page already exists
            let page = store.getters.getPageByHandle(handle)

            // If page already exists, resolve with the page value
            if(typeof page !== 'undefined') {
                resolve(page)
            // If page doesn't exist, load page
            } else {
                fetchApi(queries[handle])
                    .then(r => {
                        store.commit('addPage', r.entry)
                        resolve(r.entry)
                    })
            }

        })
    },
}

// Mutations
const mutations = {
    addPage(state, page) {
        state.pages.push(page)
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
