import Vue from 'vue';
import VueRouter from 'vue-router'
import store from 'src/store'

import Home from 'templates/views/Home'
import Search from 'templates/views/Search'
import Funds from 'templates/views/Funds'
import Directory from 'templates/views/Directory'
import Collection from 'templates/views/Collection'
import About from 'templates/views/About'
import Photo from 'templates/views/Photo'
import Order from 'templates/views/Order'

import { EventBus } from 'src/event-bus'

// Use plugin
Vue.use(VueRouter)

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            section: 'home',
            bgColor: 'beige',
        }
    },
    {
        name: 'search',
        path: '/recherche',
        component: Search,
        meta: {
            bgColor: 'white',
        }
    },
    {
        name: 'funds',
        path: '/fonds',
        component: Funds,
        meta: {
            section: 'funds',
            bgColor: 'beige',
        }
    },
    {
        name: 'directory',
        path: '/territoires-et-thematiques',
        component: Directory,
        meta: {
            section: 'directory',
            bgColor: 'beige',
        }
    },
    {
        name: 'collection',
        path: '/collection/:id/:slug?',
        component: Collection,
        meta: {
            section: 'category',
            bgColor: 'beige',
        }
    },
    {
        name: 'about',
        path: '/a-propos',
        component: About,
        meta: {
            section: 'about',
            bgColor: 'beige',
        }
    },
    {
        name: 'photo',
        path: '/photo/:reference',
        component: Photo,
        meta: {
            section: 'photo',
            bgColor: 'white',
        },
    },
    {
        name: 'order',
        path: '/demande-achat/:reference',
        component: Order,
        meta: {
            section: 'photo',
            bgColor: 'white',
        },
    },
    {
        name: 'Error404',
        path: '*',
        redirect: '/'
    }
]

// Create the router with the routes
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
    linkActiveClass: 'is-active',
})

// Before each route load
router.beforeEach((to, from, next) => {

    EventBus.$emit('cursor-reset')

    //
    store.dispatch('global/error', false)

    // Set search active
    store.dispatch('global/setSearch', to.name === 'search')

    // Set previous route if exists
    const previous = from.name !== null && from.name !== 'order' ? from : false
    store.dispatch('global/addPreviousRoute', previous)

    store
        .dispatch('global/loadContent', to)
        .then(next)
        .catch(e => {

            if (e.code === 404) {
                console.error(e.message) // eslint-disable-line
                //store.dispatch('global/error')
                //window.location = '/'
            }
        })
})


// Link resolver
const linkResolver = (handle) => {

    const route = routes.find(route => route.meta.section === handle)

    if(route) {
        return route.path
    } else {
        return '/'
    }
}

// Export
export default router
export { router, routes, linkResolver }
