<template>
    <span
        v-if="previous"
        class="o-btn-return"
        :class="{ 'is-visible' : isVisible }"
    >
        <btn-circle
            tag="router-link"
            :href="previous"
            title="Retour"
            icon="arrow-left"
            display="border"
            size="sm"
        />
        <btn
            iconBefore="arrow-left"
            label="Retour"
            @click.native="goBack()"
        />
    </span>
</template>

<script>

import BtnCircle from 'objects/BtnCircle';
import Btn from 'components/Btn';

export default {
    name: 'BtnReturn',
    components: {
        BtnCircle,
        Btn,
    },
    props: {
        default: {
            default: '/'
        },
    },
    data: () => ({
        from: null,
        isVisible: false,
    }),
    mounted() {
        this.isVisible = true
    },
    computed: {
        previousPath() {
            return this.$store.getters['global/getPreviousRoutePath']
        },
        previous() {
            if (this.previousPath) {
                return this.previousPath
            } else {
                return this.default
            }
        },
    },
    methods: {
        goBack() {
            this.$router.push(this.previous, () => {
                this.$store.dispatch('global/removeLastPreviousRoute')
            })

            this.$store.dispatch('global/removeLastPreviousRoute')
        }
    }
};

</script>

<style lang="scss">

.o-btn-return {
    z-index: 1;
    position: absolute;
    top: 0;
    top: calc((#{$header-size} - 2.75em)/2);
    left: calc((#{$header-size} - 2.75em)/2 - var(--grid-gutter));
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    height: 2.75em;
    opacity: 0;

    &.is-visible {
        opacity: 1;
        transition: opacity .4s ease-out 1s;
    }

    @media #{md("sm", "max")} {

        .c-btn {
            display: none;
        }
    }

    @media #{md("sm")} {
        top: 0;
        left: var(--grid-gutter);
        width: auto;
        height: $header-size;
        align-items: flex-end;

        > .o-btn-circle {
            display: none;
        }
    }
}

</style>
