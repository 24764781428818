import mainStore from 'src/store';
import {fetchApi} from 'src/graphql/config';
import queries from 'src/graphql/queries';

/*
 * Global
 */

// State
const state = {
    nav: [],
    currentPage: {},
    currentPageName: null,
    user: null,
    bgColor: null,
    showCanvasTutorial: true,
    previousRoute: [],

    cloudfrontUrl: '',
    searchIsActive: false,
    error: false
};

// Getters
const getters = {
    getCurrentPage: state => state.currentPage,
    getCurrentPageName: state => state.currentPageName,
    getBgColor: state => state.bgColor,
    getUser: state => state.user,
    showCanvasTutorial: state => state.showCanvasTutorial,
    getPreviousRoutePath: state => {
        const length = state.previousRoute.length
        return length > 0 ? state.previousRoute[length - 1].path : false
    },
    searchIsActive: state => state.searchIsActive,
    error: state => state.error
};

// Actions
const actions = {
    initLoad(store) {

        // Start loader
        store.dispatch('loader/startLoad', null, {root: true});

        return Promise.all([
            store.dispatch('loadNav'),
            store.dispatch('loadCloudFront'),
            store.dispatch('categories/loadAll', null, {root: true}),
            store.dispatch('photos/loadData', null, {root: true}),
        ]).then(() => {
            store.dispatch('loader/endLoad', null, {root: true});
        })
    },
    loadContent(store, to) {

        return new Promise((resolve, reject) => {

            // Start loader
            store.dispatch('loader/startLoad', null, {root: true});

            // Set next page background color
            store.commit('setBgColor', to.meta.bgColor);

            // Load page content
            let loadContent;
            let section = to.meta.section;

            if (section === 'photo') {

                let reference = to.params.reference;

                // loadContent = store.dispatch('photos/loadPhoto', id)
                loadContent = store.dispatch('photos/loadPhoto', reference, {root: true});

            } else if (section === 'category') {

                let id = to.params.id;

                loadContent = store.dispatch('categories/loadCategory', id, {root: true});

            } else if (section) {

                loadContent = store.dispatch('pages/loadPage', section, {root: true});

            } else {

                loadContent = new Promise(resolve => resolve({}));
            }

            loadContent
                .then(page => {

                    const watcher = mainStore.watch(() => mainStore.getters['loader/isLoading'], loading => {
                        if (loading == 0) {
                            store.commit('setPage', page);
                            watcher();
                            resolve(page);
                        }
                    });

                    // End loader
                    store.dispatch('loader/endLoad', null, {root: true});
                })
                .catch(error => {
                    reject(error)
                })

        });
    },
    loadNav(store) {

        return fetchApi(queries.nav)
            .then(data => {

                // Format nav and add route path to each nav item
                const assets = data.assets
                const nav = data.globalSet.navNodes
                const navLength = nav.length

                for (let i = 0; i < navLength; i++) {
                    nav[i].assets = assets.slice(i * 4, (i+1) * 4)
                }

                store.commit('setNav', nav);
                return nav
            })
    },
    loadCloudFront(store) {

        return fetch('/actions/awsmodule/asset/global',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(r => r.json())
            .then(data => {
                store.commit('setGlobalData', data);
                return data;
            })
            .catch(error => {
                console.error('fetchApi error:', error)  // eslint-disable-line
                return error;
            });
    },
    setUser(store, user) {
        store.commit('setUser', user);
    },
    setSearch(store, isActive) {
        store.commit('setSearch', isActive);
    },
    disableCanvasTutorial(store) {
        store.commit('setCanvasTutorial', false);
    },
    updatePage(store, page) {
        store.commit('setPage', page);
    },
    updatePageName(store, name) {
        store.commit('setPageName', name)
    },
    addPreviousRoute(store, route) {
        store.commit('addPreviousRoute', route);
    },
    removeLastPreviousRoute(store) {
        if(store.state.previousRoute.length > 0) {
            store.commit('removeLastPreviousRoute');
        }
    },
    error(store, error = true) {
        store.commit('setError', error);
    }
};

// Mutations
const mutations = {
    setNav(state, nav) {
        state.nav = nav;
    },
    setPage(state, page) {
        state.currentPage = page;
    },
    setPageName(state, name) {
        state.currentPageName = name
    },
    setBgColor(state, color) {
        state.bgColor = color;
    },
    setUser(state, user) {
        state.user = user;
    },
    setSearch(state, isActive) {
        state.searchIsActive = isActive;
    },
    setGlobalData(state, global) {
        state.cloudfrontUrl = global.cloudfrontUrl;
    },
    setCanvasTutorial(state, enabled) {
        state.showCanvasTutorial = enabled
    },
    addPreviousRoute(state, route) {
        state.previousRoute.push(route)
    },
    removeLastPreviousRoute(state) {
        state.previousRoute.pop()
    },
    setError(state, value) {
        state.error = value;
    }
};


// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
