<template>
    <component
        :is="tag"
        :href="tag == 'a' && href !== null ? href : false"
        :to="tag == 'router-link' && href !== null ? href : false"
        :rel="tag == 'a' ? 'noopener' : false"
        :title="tag == 'a' || tag == 'router-link' ? label : false"
        :class="className"
        @mouseenter="isHovering = true"
        @mouseleave="isHovering = false"
        v-cursor
    >
        <component
            v-if="iconBefore !== null"
            v-bind="iconAttrs"
            class="c-btn__icon"
            :icon="iconBefore"
            :hover="isHovering"
        />
        <span class="c-btn__label">
            <slot>{{ label }}</slot>
        </span>
        <component
            v-if="iconAfter !== null"
            v-bind="iconAttrs"
            class="c-btn__icon"
            :icon="iconAfter"
            :hover="isHovering"
        />
        <slot name="after"></slot>
    </component>
</template>

<script>

import Icon from 'objects/Icon';
import BtnCircle from 'objects/BtnCircle';

export default {
    name: 'Btn',
    components: {
        Icon,
        BtnCircle,
    },
    props: {
        tag: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: 'Button Label',
        },
        size: {
            type: String,
            default: 'md',
        },
        modifier: {
            type: String,
            default: null,
        },
        iconBefore: {
            type: String,
            default: null,
        },
        iconAfter: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        isHovering: false,
    }),
    computed: {
        className() {
            let classname = 'c-btn'

            if(this.modifier) {
                classname += ` -${this.modifier}`
            }

            if(this.size) {
                classname += ` -${this.size}`
            }

            return classname
        },
        iconAttrs() {
            const small = this.size === 'sm';

            return {
                is: small ? Icon : BtnCircle,
                tag: small ? false : 'span',
                color: small ? false : 'border'
            }
        },
    }
}
</script>

<style lang="scss">

.c-btn {
    display: inline-flex;
    align-items: center;
    padding-top: .3em;
    padding-bottom: .3em;
    color: $color-dark;
    font-family: ff("serif");
    cursor: pointer;

    &:after {
        @include pseudo-el($height: 1px, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .o-btn-circle {
        --size: 1.4em;
    }

    // Sizes
    &.-sm {
        padding: 0;
        font-family: ff("sans");
        font-size: .75em;
    }

    &.-md {
        font-size: 1.1em;
    }

    &.-lg {
        @include responsive-type($min-font: 24, $max-font: 45, $min-width: 0, $max-width: 1920);
    }

    // Modifiers
    &.-hover {

        &:after {
            transform: scale(0, 1);
            transform-origin: 100% 50%;
            transition: transform .8s $out-expo;
        }

        &:hover:after {
            transform: scale(1);
            transform-origin: 0 50%;
        }
    }

    &.-secondary {
        color: $color-grey;
    }

    @media #{md("sm")} {

        &.-sm {
            font-size: .875em;
        }

        &.-md {
            font-size: 1.25em;
        }
    }
}

.c-btn__label {

    & + .c-btn__icon {
        margin-left: .5em;
    }
}

.c-btn__icon {
    flex-shrink: 0;

    & + .c-btn__label {
        margin-left: .5em;
    }
}

</style>
