<template>
    <div
        class="o-grid-assets | grid"
    >
        <div
            ref="grid"
            class="o-grid-assets__grid"
        >
            <div
                v-for="(col, i) in columnCount"
                :key="`column-${i}`"
                ref="column"
                class="o-grid-assets__col"
            >
                <div
                    v-for="(asset, j) in columns[i]"
                    :key="`asset-${j}`"
                    ref="cell"
                    class="o-grid-assets__cell"
                >
                    <asset
                        v-bind="asset"
                        :path="`/${asset.reference}`"
                        :hover="true"
                        crop="grid"
                        sizes="94w, (min-width: 480px) 45w, (min-width: 768px) 27w, (min-width: 992px) 21w"
                    />
                </div>
            </div>
        </div>

        <span
            v-if="noResults"
            class="o-grid-assets__empty | t-t3"
        >
            Il n'y a aucune photographie
        </span>

        <span
            class="o-grid-assets__loading"
            :class="{'-show': isLoading || isUpdatingLayout}"
        >
            <span>
                <svg
                    class="progress-ring"
                    width="2em"
                    height="2em"
                >
                    <circle
                        class="progress-ring__circle"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="transparent"
                        r="calc(1em - 4px)"
                        cx="1em"
                        cy="1em"/>
                </svg>
            </span>
        </span>

        <span
            ref="bottom"
            class="o-grid-assets__bottom"
        >
        </span>
    </div>
</template>

<script>

import Asset from 'objects/Asset'

export default {
    name: 'GridAssets',
    components: {
        Asset,
    },
    data: () => ({
        items: [],
        loadCount: 0,
        loadLimit: 12,
        lastLoad: false,

        columns: [],
        columnCount: 0,
        columnWidth: 0,
        columnHeights: null,
        isUpdatingLayout: false,

        prevScroll: 0,
        highestScroll: 0,
        isLoading: false,
        windowHeight: window.innerHeight,

        isHovering: false,
    }),
    props: {
        relatedTo: {
            type: String|Array,
            default: null,
        },
        searchQuery: false,
    },
    mounted() {
        this.loadPhotos()

        // Events
        document.querySelector('.l-wrapper').addEventListener('scroll', this.scroll)
        window.addEventListener('scroll', this.scroll)
        window.addEventListener('resizeEnd', this.resize)

        // Trigger scroll and resize function on component creation
        this.resize()
        this.scroll()
    },
    computed: {
        query() {
            let query = ''

            if(this.relatedTo) {
                query += `${this.relatedTo}`
            }

            if(this.searchQuery) {
                query += `${this.searchQuery}`
            }

            return query
        },
        previousQuery() {
            return this.$store.state.search.query
        },
        noResults() {
            return this.lastLoad && this.items.length === 0
        }
    },
    methods: {
        loadPhotos() {
            this.isLoading = true

            const args = `
                limit: ${this.loadLimit},
                offset: ${this.loadCount * this.loadLimit},
                ${this.query}
            `

            this.$store.dispatch('photos/loadPhotos', args)
                .then(photos => {
                    this.isUpdatingLayout = true

                    this.items = this.items.concat(photos)

                    this.$nextTick(() => this.layoutItems(photos))

                    this.isLoading = false

                    if (photos.length < this.loadLimit) {
                        this.lastLoad = true
                    }
                })

            // Increment loadCount
            this.loadCount++
        },

        scroll() {

            // Return if currently loading photos
            if (this.isLoading || this.isUpdatingLayout) {
                return
            }

            const scrollValue = window.scrollY

            // Return if scroll up or scrolled less than highest scroll
            if (this.prevScroll > scrollValue || scrollValue < this.highestScroll) {
                this.prevScroll = scrollValue
                return
            }

            const rect = this.$refs.bottom.getBoundingClientRect()

            // If loader is near, load more photos
            if(rect.top < this.windowHeight * 2) {
                this.loadPhotos()
            }


            // Update highest scroll if current scroll is higher
            if (scrollValue > this.highestScroll) {
                this.highestScroll = scrollValue
            }

            // Set previous scroll to current scroll
            this.prevScroll = scrollValue
        },
        resize() {
            this.windowHeight = window.innerHeight
            this.prevScroll = 0
            this.highestScroll = 0
            this.scroll()

            // Grid column count
            const gridStyles = window.getComputedStyle(this.$refs.grid)
            this.columnCount = parseInt(gridStyles.getPropertyValue('--column-count'))
        },

        layoutItems(items) {

            let minHeight, index
            items.forEach(item => {

                // layout item by appending to column
                minHeight = Math.min(...this.columnHeights);
                index = this.columnHeights.indexOf(minHeight);
                this.columns[index].push(item);

                // Add item ratio to column height or increment if not loaded
                this.columnHeights[index] += (item.height/item.width) * this.columnWidth || 1;
            });

            this.isUpdatingLayout = false
        },

        refresh() {
            this.items = []
            this.resize()
            this.scroll()
        },
    },
    watch: {
        columnCount(cols) {
            this.columns = []
            this.columnHeights = []
            for (let i = 0; i < cols; i++) {
                this.columnHeights.push(0)
                this.columns.push([])
            }

            this.$nextTick(() => {
                this.columnWidth = this.$refs.column[0].offsetWidth
                this.layoutItems(this.items)
            })
        },
        lastLoad(last) {

            // Remove scorll listener if no more assets to load
            if (last) {
                window.removeEventListener('scroll', this.scroll)
            }
        },
        isLoading(loading) {
            this.$emit('loading', loading)
        },
    },
    destroyed() {

        // Events
        document.querySelector('.l-wrapper').removeEventListener('scroll', this.scroll)
        window.removeEventListener('scroll', this.scroll)
        window.removeEventListener('resizeEnd', this.resize)
    }
};

</script>

<style lang="scss">

.o-grid-assets {
    margin-bottom: 10vh;
}

.o-grid-assets__grid {
    --column-count: 1;

    display: grid;
    grid-template-columns: repeat(var(--column-count), minmax(0, 1fr));
    grid-column-gap: var(--grid-gutter);

    @media #{md("xs")} {
        --column-count: 2;
    }

    @media #{md("sm")} {
        --column-count: 3;
    }

    @media #{md("md")} {
        --column-count: 4;
    }
}

.o-grid-assets__cell {
    margin-top: var(--grid-gutter-half);
}

.o-grid-assets__empty {
    display: block;
    text-align: center;
}

.o-grid-assets__loading {
    position: fixed;
    bottom: calc( env(safe-area-inset-bottom) + var(--grid-gutter-half));

    transition: transform .2s $out-expo, opacity .2s $out-expo;
    transition-delay: 1.5s;
    transform: translateY(100%);
    opacity: 0;
    will-change: transform;

    &.-show {
        transition: transform .4s $out-expo, opacity .4s $out-expo;
        transition-delay: 0s;
        transform: translateY(0%);
        opacity: 1;
    }

    span {
        display: block;
        width: 2.4em;
        height: 2.4em;
        padding: 0.2em;
        background: #FFF;
        border-radius: 100%;
    }
    svg {
        width: 2em;
        height: 2em;
        stroke-dasharray: 4 4;
        animation: rotating 3s linear infinite;
        transform-origin: center;
    }
}
.o-grid-assets__bottom {
    display: block;
}

/*
.o-grid-assets__grid {
    grid-column-gap: var(--grid-gutter-half);
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 0;
    grid-row-gap: 2px;

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media #{md("md")} {
        grid-template-columns: repeat(4, 1fr);
    }
}

.o-grid-assets__cell {
    //display: flex;
    align-items: center;

    .o-asset {
       padding-top: var(--grid-gutter-half);

    }
}
*/

.o-grid-assets__hover {
    z-index: 1;
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    justify-content: center;
    pointer-events: none;
    mix-blend-mode: difference;
    transform: translate(0, -50%);
    transition: color .4s ease-out;

    .o-at__w {
        // width: 100%;
        // justify-content: center;
    }

    @media #{md("sm")} {
        right: $header-size;
        left: $header-size;
        width: auto;
        margin-right: 0;
        margin-left: 0;
    }
}
</style>
