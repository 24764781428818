<template>
    <div :class="className">
        <app-header ref="header" @toggleNav="toggleNav" />
        <grid-layout ref="gridLayout" />

        <div class="l-wrapper__inner">
            <main class="l-main" ref="main">
                <router-view v-show="hasCurrentPage" :page="currentPage"/>
            </main>
        </div>

        <app-footer />
    </div>
</template>

<script>

import AppHeader from 'layout/AppHeader';
import AppFooter from 'layout/AppFooter';
import GridLayout from 'layout/GridLayout';

import isEmpty from 'lodash/isEmpty';

import { mapGetters } from 'vuex'

export default {
    name: 'app',
    components: {
        AppHeader,
        AppFooter,
        GridLayout,
    },
    data: () => ({
        navIsOpen: false,
    }),
    mounted() {
        // Animate nav out
        this.$refs.gridLayout.animate(this.bgColor)
    },
    computed: {
        ...mapGetters('loader', {
            isLoading: 'isLoading',
        }),
        ...mapGetters('global', {
            bgColor: 'getBgColor',
        }),
        className() {
            let classname = 'l-wrapper'

            if (this.currentPageName) {
                classname += ` p-${this.currentPageName}`
            }

            if(this.bgColor) {
                classname += ` is-${this.bgColor}`
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += ' nav-is-open'
            }

            return classname
        },
        currentPage() {
            return this.$store.getters['global/getCurrentPage']
        },
        currentPageName() {
            return this.$store.getters['global/getCurrentPageName']
        },
        hasCurrentPage() {
            return !isEmpty(this.currentPage) || this.$route.name === 'search'
        },
    },
    methods: {
        toggleNav(open) {
            this.navIsOpen = open

            if(open) {
                this.$refs.gridLayout.coverIn('black', true)
            } else {
                this.$refs.gridLayout.coverOut()
            }
        },
    },
    watch: {
        isLoading(loading) {
            if (loading) {

                // Animate nav out
                this.$refs.gridLayout.animate(this.bgColor)

                // Close nav if open
                this.$refs.header.close();
            }
        },
        $route(to) {
            this.$el.scroll(0, 0)
            this.$store.dispatch('global/updatePageName', to.name)
        },
    },
}

</script>
