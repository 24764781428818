<template>
    <div class="o-input-range">
        <span class="o-input-range__label | t-t5">
            {{ label }}
        </span>
        <div
            ref="slider"
            class="o-input-range__slider"
        >
            <span
                ref="range"
                class="o-input-range__range"
            ></span>
            <button
                ref="handleStart"
                class="o-input-range__btn"
                :aria-label="`${label} début`"
                v-cursor.slideX
            >
                <span class="o-input-range__handle"></span>
                <span class="o-input-range__value | t-small">{{ valueStart }}</span>
            </button>
            <button
                ref="handleEnd"
                class="o-input-range__btn"
                :aria-label="`${label} fin`"
                v-cursor.slideX
            >
                <span class="o-input-range__handle"></span>
                <span class="o-input-range__value | t-small">{{ valueEnd }}</span>
            </button>
        </div>
    </div>
</template>

<script>

import Icon from 'objects/Icon';

import { gsap, Draggable } from 'gsap/all'
gsap.registerPlugin(Draggable);

export default {
    name: 'AppInput',
    components: {
        Icon,
    },
    props: {
        label: {
            type: String,
            default: 'Placeholder',
        },
        start: Number,
        end: Number,
        defaultValue: false,
    },
    data: () => ({
        valueStart: 0,
        valueEnd: 0,
    }),
    computed: {
        range() {
            return [this.valueStart, this.valueEnd].sort((a, b) => a - b)
        },
    },
    created() {
        this.valueStart = this.start
        this.valueEnd = this.end
    },
    mounted() {

        this.setSizes()

        this.draggables = Draggable.create([this.$refs.handleStart, this.$refs.handleEnd], {
            type: 'x',
            bounds: {
                left: -this.handleOffset,
                width: this.containerWidth + this.handleWidth
            },
            cursor: 'pointer',
            onDrag : this.updateRange,
            zIndexBoost: false,
            allowEventDefault: true,
        });

        Draggable.create(this.$refs.slider, {
            bounds: this.$refs.slider,
            cursor: 'pointer',
            onPress: this.setKnob,
            zIndexBoost: false,
            allowEventDefault: true,
        });

        this.draggable1 = this.draggables[0];
        this.draggable2 = this.draggables[1];

        if(this.defaultValue) {
            this.init(this.defaultValue[0], this.defaultValue[1])
        } else {
            this.init(this.start, this.end)
        }

        // Window events
        window.addEventListener('resizeEnd', this.resize)
    },
    methods: {

        init(start, end) {

            gsap.set(this.draggable1.target, {
                x: this.getPosition(start)
            });
            gsap.set(this.draggable2.target, {
                x: this.getPosition(end)
            });

            this.draggable1.update();
            this.draggable2.update();
            this.updateRange();
        },

        getValue(position) {

            var ratio = position / this.containerWidth;
            var value = ((this.end - this.start) * ratio) + this.start;

            return value;
        },

        getPosition(value) {

            var ratio = (value - this.start) / (this.end - this.start);
            var position = (ratio * this.containerWidth) - this.handleOffset;

            return position;
        },

        setKnob(event) {

            if (event.target === this.draggable1.target ||
                event.target === this.draggable2.target) {
                return
            };

            const mouseX = event.x - this.containerX;

            var d1 = Math.abs(mouseX - this.draggable1.x);
            var d2 = Math.abs(mouseX - this.draggable2.x);

            // Select the closest knob
            var knob = d1 > d2 ? this.draggable2 : this.draggable1;

            gsap.set(knob.target, {
                x: mouseX - this.handleOffset,
                onComplete: () => {
                    knob.startDrag(event);
                }
            });

            this.updateRange();
        },

        getRange(knob) {

            var range = knob.x + this.handleOffset;

            range = range < 1
                ? 0 : range > this.containerWidth
                ? this.containerWidth : range;

            return range;
        },


        updateRange (){

            var range1 = this.getRange(this.draggable1);
            var range2 = this.getRange(this.draggable2);

            this.valueStart = Math.round(this.getValue(range1))
            this.valueEnd = Math.round(this.getValue(range2))

            if ( range1 < range2 ) {
                gsap.set(this.$refs.range, { x: range1, width: range2 - range1 });
            } else {
                gsap.set(this.$refs.range, { x: range2, width: range1 - range2 });
            }
        },

        setSizes() {
            const rect = this.$refs.slider.getBoundingClientRect()
            this.containerX = rect.left;
            this.containerWidth = rect.width;

            this.handleWidth = this.$refs.handleStart.offsetWidth;
            this.handleOffset = this.handleWidth / 2;
        },
        resize() {

            this.setSizes()

            const tempStart = this.valueStart
            const tempEnd = this.valueEnd

            //this.containerWidth = this.$refs.slider.offsetWidth

            this.draggables.forEach(draggable => {
                draggable.applyBounds({
                    left: -this.handleOffset,
                    width: this.containerWidth + this.handleWidth
                })
            })

            this.init(tempStart, tempEnd);
        },

        reset() {
            this.init(this.start, this.end)
        }
    },
    watch: {
        valueStart() {
            this.$emit('input', this.range)
        },
        valueEnd() {
            this.$emit('input', this.range)
        },
    },
    destroyed() {
        // Window events
        window.removeEventListener('resizeEnd', this.resize)
    }
};

</script>

<style lang="scss">

.o-input-range {}

.o-input-range__slider {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    background-color: $color-dark;
}

.o-input-range__range {
    position: absolute;
    top: calc(50% -2px/2);
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $color-blue-bright;
}

.o-input-range__btn {
    position: absolute;
    top: 0;
    left: 0;
}

.o-input-range__handle {
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin-top: calc(-1.5em/2);
    background-color: $color-blue-bright;
    border-radius: 50%;
}

.o-input-range__value {
    position: absolute;
    top: 100%;
    left: calc(50% - 2em/2);
    width: 2em;
    display: block;
    margin-top: .5em;
    text-align: center;
}

</style>
