<template>
    <article
        v-if="page && page.categoryFund"
        :key="`photo-${page.id}`"
        class="p-photo | l-content"
    >

        <btn-return />

        <div class="grid">

            <anim-text
                :text="page.cleanTitle"
                :show="true"
                type="chars"
                class="p-photo__title | t-t1"
            />

            <div>
                <!-- <anim-text
                    :text="date"
                    :show="true"
                    type="chars"
                    tag="span"
                    class="p-photo__date | t-t2-sans"
                /> -->

                <asset
                    v-bind="page"
                    crop="detail"
                    :zoom="true"
                    sizes="75w, (min-width: 768px) 35w"
                    class="p-photo__asset"
                />
            </div>

            <div class="grid__row | u-jc-center">
                <div class="grid__col--22 grid__col--20@xs grid__col--18@sm grid__col--12@md grid__col--10@lg">
                    <div class="o-data">
                        <div class="o-data__item">
                            <anim-text
                                :text="title"
                                :reveal="true"
                                type="chars"
                                tag="span"
                                class="t-t3 | u-c-dark"
                            />
                        </div>

                        <div
                            v-if="fund || region"
                            class="o-data__item"
                        >
                            <!-- <anim-text
                                text="Collections"
                                :reveal="true"
                                type="words"
                                tag="h3"
                                class="t-t5"
                            /> -->
                            <tags :tags="[fund, region]" />
                        </div>
                        <div class="o-data__item | o-table">
                            <div class="o-table__item">
                                <anim-text
                                    :text="hasPeriod ? 'Période' : 'Année'"
                                    :reveal="true"
                                    type="words"
                                    tag="h4"
                                    class="o-table__label | t-t5"
                                />
                                <anim-text
                                    :text="date !== null ? date : 'inconnu'"
                                    :reveal="true"
                                    type="words"
                                    tag="span"
                                    class="t-t5"
                                />
                            </div>
                            <div class="o-table__item">
                                <anim-text
                                    text="Dimensions"
                                    :reveal="true"
                                    type="words"
                                    tag="h4"
                                    class="o-table__label | t-t5"
                                />
                                <anim-text
                                    :text="page.photoWidth !== null && page.photoHeight !== null ? `${page.photoWidth} cm x ${page.photoHeight} cm` : 'inconnues'"
                                    :reveal="true"
                                    type="words"
                                    tag="span"
                                    class="t-t5"
                                />
                            </div>
                            <div class="o-table__item">
                                <anim-text
                                    text="Numéro d’accession"
                                    :reveal="true"
                                    type="words"
                                    tag="h4"
                                    class="o-table__label | t-t5"
                                />
                                <anim-text
                                    :text="reference"
                                    :reveal="true"
                                    type="words"
                                    tag="span"
                                    class="t-t5"
                                />
                            </div>
                        </div>
                        <div
                            v-if="page.tags.length > 0"
                            class="o-data__item"
                        >
                            <anim-text
                                text="Mots-clés reliés"
                                :reveal="true"
                                type="words"
                                tag="h3"
                                class="t-t5"
                            />
                            <tags :tags="page.tags" />
                        </div>
                        <div class="o-data__item -btn">
                            <btn
                                tag="router-link"
                                :href="`/demande-achat/${page.reference}`"
                                label="Demande d'achat"
                                iconAfter="arrow-right"
                            />
                        </div>
                        <div
                            v-if="info && (info.intro || info.email)"
                            class="o-data__item -infos"
                            :class="{ 'is-hovering' : isHoveringInfo }"
                        >
                            <p class="t-small">{{ info.intro }}</p>
                            <btn
                                tag="a"
                                :href="`mailto:${info.email}?subject=Cyberphotos: Informations manquantes #${reference}`"
                                target="_blank"
                                rel="noopener"
                                size="sm"
                                label="Faites-nous en part"
                                @mouseenter.native="isHoveringInfo = true"
                                @mouseleave.native="isHoveringInfo = false"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="fund"
            class="p-photo__fund"
        >
            <anim-text
                :text="fund.title"
                :show="true"
                tag="h2"
                type="chars"
                class="p-photo__fund-title | t-t1"
            />

            <grid-assets
                :relatedTo="relatedTo"
                :searchQuery='`id: "not ${page.id}"`'
            />
        </div>

    </article>
</template>

<script>

import Icon from 'objects/Icon';
import Tags from 'objects/Tags';
import Asset from 'objects/Asset';
import AnimText from 'objects/AnimText';
import BtnReturn from 'objects/BtnReturn';
import GridAssets from 'objects/GridAssets';
import Btn from 'components/Btn';

export default {
    name: 'Photo',
    components: {
        Icon,
        Tags,
        Asset,
        AnimText,
        Btn,
        BtnReturn,
        GridAssets,
    },
    metaInfo() {
        return {
            title: this.seoTitle,
        }
    },
    data: () => ({
        error: null,
        info: false,
        isHoveringInfo: false,
    }),
    props: {
        page: {},
    },
    created() {
        this.$store.dispatch('photos/loadMissingInfos')
            .then(r => {
                this.info = r
            })
    },
    computed: {
        seoTitle() {
            if (!this.page || !this.page.reference)
                return

            return `Nº ${this.page.reference.toUpperCase()} › ${this.page.cleanTitle} - Cyberphotos`
        },
        date() {
            return this.hasPeriod ? `${this.page.date}-${this.page.dateEnd}` : `${this.page.date}`
        },
        hasPeriod() {
            return this.page.dateEnd !== null && this.page.date !== this.page.dateEnd
        },
        reference() {
            return this.page.reference !== null ? this.page.reference : 'inconnu'
        },
        title() {
            let title = this.page.cleanTitle

            if(this.region > 0) {
                title += `, ${this.region.title}`
            }

            return title
        },
        region() {
            const region = this.page.categoryRegion

            return region && region.length > 0 ? region[0] : false
        },
        fund() {
            const fund = this.page.categoryFund

            return fund.length > 0 ? fund[0] : false
        },
        relatedTo() {
            if (!this.fund)
                return

            const fund = this.fund.id
            return `relatedToCategories: [{group: "funds", id: [${fund}]}]`
        }
    }
};

</script>

<style lang="scss">

// .p-photo {}

.p-photo__title {
    max-height: 4em;
    overflow: hidden;

    @media #{md("sm", "max")} {
        margin-left: calc(#{$header-size} - var(--grid-gutter) * 2);
        padding-top: .4em;
        padding-bottom: .2em;
    }

    @media #{md("sm")} {
        position: absolute;
        top: .1em;
        right: 0;
        left: 0;
        justify-content: center;
        width: auto;
        padding-right: 8rem;
        padding-left: 8rem;
    }
}

// .p-photo__date {
//     --anim-delay: .6s;

//     position: absolute;
//     left: 0;
//     width: 100%;

//     @media #{md("sm", "max")} {
//         bottom: -.75em;
//     }

//     @media #{md("sm")} {
//         top: 50%;
//     }
// }

.p-photo__asset {
    width: 75vw;
    margin-right: auto;
    margin-left: auto;

    @media #{md("sm", "max")} {
        margin-bottom: 2em;
    }

    @media #{md("sm")} {
        width: 35vw;
        padding-top: 10vh;
    }
}


.p-photo__fund {
    margin-top: 10vh;
}

.p-photo__fund-title {
    top: .6em;
}


/*----------  Data  ----------*/

.o-data__item {
    margin-top: 2em;

    &.-btn {
        margin-bottom: 4em;
    }

    &.-infos,
    &.-contact {
        padding: 1em;
        color: $color-dark;
    }

    &.-infos {
        background-color: $color-beige;
        transition: background-color .3s ease-out;

        &.is-hovering {
            background-color: $color-peach;
        }
    }

    &.-contact {
        background-color: $color-blue-light;

        a {
            color: $color-blue;
        }
    }

    @media #{md("xs", "max")} {

        &.-infos {

            .c-btn {
                margin-top: 1em;
            }
        }
    }

    @media #{md("xs")} {

        &.-infos {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .c-btn {
                margin-left: 1em;
                white-space: nowrap;
            }
        }
    }
}


/*----------  Table  ----------*/

.o-table {
    display: flex;
    flex-wrap: wrap;
}

.o-table__item {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    flex: 1 1 0;
    padding-top: .5em;
    padding-right: .5em;
    padding-bottom: .5em;

    @media #{md("xs", "max")} {
        min-width: 50%;

        &:nth-child(n+3) {
            margin-top: 1em;
        }

        &:nth-child(2n) {
            border-left: 1px solid $color-dark;
            padding-left: .5em;
        }
    }

    @media #{md("xs")} {

        &:nth-child(n+2) {
            border-left: 1px solid $color-dark;
            padding-left: .5em;
        }
    }
}

.o-table__label {
    margin-bottom: 1.5em;
}

</style>
