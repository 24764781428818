import { fetchApi , fetchJSON, photosJSON } from 'src/graphql/config';
import queries from 'src/graphql/queries';

/*
** Pages
*/

// State
const state = {
    photos: {},
    missingInfos: null,
    totalPhotos: window.totalPhotos || 0,
    dateStart: 1850,
    dateEnd: 1990,
};

// Getters
const getters = {
    //getPhotoById: state => id => state.photos[id],
    getPhotoByReference: state => reference => state.photos[reference]
};

// Actions
const actions = {
    loadData(store) {

        return fetchJSON(photosJSON)
            .then(r => {
                store.commit('setState', { key: 'totalPhotos', value: r.totalPhotos});
                store.commit('setState', { key: 'dateStart', value: r.photoDateStart});
                store.commit('setState', { key: 'dateEnd', value: r.photoDateEnd});
            })
    },
    loadPhoto(store, reference) {

        return new Promise((resolve, reject) => {

            // Search if page already exists
            let photo = store.getters.getPhotoByReference(reference);

            // If photo already exists, resolve with the photo value
            if (typeof photo !== 'undefined' && photo.isSingleFetched) {
                resolve(photo);
            // If photo doesn't exist, load photo
            } else {

                fetchApi(queries.photo(reference))
                    .then(r => {

                        if (r && r.asset) {
                            const photo = r.asset;
                            photo.isSingleFetched = true
                            store.commit('addPhoto', photo);
                            resolve(photo);
                        } else {
                            reject({
                                code: 404,
                                message: 'Photo not found'
                            })
                        }
                    })
                    .catch(e => {

                        reject({
                            code: 404,
                            message: e
                        })
                    })
            }

        });
    },
    loadPhotos(store, args) {

        return new Promise((resolve, reject) => {

            fetchApi(queries.photosBasic(args))
                .then(r => {

                    if (r && r.assets) {
                        const photos = r.assets;

                        photos.forEach(photo => {

                            // Check if photo exist in store
                            let _photo = store.getters.getPhotoByReference(photo.reference);

                            // Photo exists and fully fetched
                            if (typeof _photo !== 'undefined' && _photo.isSingleFetched) {
                                // Do nothing
                            } else {
                                photo.isSingleFetched = false
                                store.commit('addPhoto', photo);
                            }
                        });

                        resolve(photos);
                    } else {
                        reject({
                            code: 404,
                            message: 'Errors with the photos query'
                        })
                    }
                })
                .catch(e => {

                    reject({
                        code: 404,
                        message: e
                    })
                })

        });
    },
    loadMissingInfos(store) {

        return new Promise((resolve, reject) => {

            // If infos are loaded already
            if (store.state.missingInfos !== null) {
                resolve(store.state.missingInfos);
            // Else load infos
            } else {

                fetchApi(queries.missingInfos)
                    .then(data => {

                        if (data) {

                            data = {
                                intro: data.globalSet.intro,
                                email: data.globalSet.email,
                                contact: data.globalSet.contact
                            }

                            store.commit('setMissingInfos', data);
                            resolve(data);

                        } else {
                            reject({
                                code: 404,
                                message: 'Errors with the general query'
                            })
                        }
                    })
                    .catch(e => {

                        reject({
                            code: 404,
                            message: e
                        })
                    })
            }
        })
    },
};

// Mutations
const mutations = {
    addPhoto(state, photo) {
        state.photos[photo.reference] = photo;
    },
    setMissingInfos(state, data) {
        state.missingInfos = data;
    },
    setState(state, { key, value}) {
        state[key] = value
    },
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
