<template>
    <div
        v-if="page && headingPhotos"
        class="p-about | l-content"
    >

        <heading
            :title="page.title"
            :heading="page.heading"
            :intro="page.intro"
            :description="page.description"
            :assets="headingPhotos"
            class="p-about__heading"
        />

        <div class="grid">

            <div class="o-stats">

                <div class="o-stats__photos">
                    <asset
                        v-for="(asset, i) in statsPhotos"
                        :key="`stats-asset-${i}`"
                        v-bind="asset"
                        crop="grid"
                        :grayscale="true"
                        :class="`o-stats__photo -i${i+1}`"
                        v-view.reveal.parallax
                    />
                </div>

                <div class="o-stats__inner">
                    <span
                        class="o-stats__item -i1 | t-t1"
                        v-view.parallax
                    >
                        <span
                            v-for="i in 3"
                            :key="`stat-bsl-${i}`"
                            class="o-stats__text"
                        >
                            Bas-Saint-Laurent
                        </span>
                    </span>
                    <span
                        class="o-stats__item -i2 | t-t1"
                        v-view.parallax
                    >
                        <span
                            v-for="i in 3"
                            :key="`stat-funds-${i}`"
                            class="o-stats__text"
                        >
                            {{ totalFunds }} fonds photographiques
                        </span>
                    </span>
                    <span
                        class="o-stats__item -i3 | t-t1"
                        v-view.parallax
                    >
                        <span
                            v-for="i in 3"
                            :key="`stat-photos-${i}`"
                            class="o-stats__text"
                        >
                            {{ totalPhotos }} photographies
                        </span>
                    </span>
                </div>

            </div>

            <div
                v-if="page.museumRedactor !== null"
                class="p-about__content | grid__row"
            >
                <anim-text
                    v-if="page.museumRedactor"
                    :text="page.museumRedactor"
                    type="html"
                    :reveal="true"
                    class="grid__col--22 grid__col--18@xs grid__col--16@sm grid__col--10@md | t-cms | u-offset-1"
                />
            </div>

            <div class="grid__row | u-jc-center">
                <div class="grid__col--24 grid__col--20@xs grid__col--18@sm grid__col--24@lg grid__col--20@xl">
                    <div
                        v-if="museum"
                        class="o-block"
                        v-view.reveal
                    >
                        <div class="o-block__content">
                            <anim-text
                                v-if="museum.heading"
                                :text="museum.heading"
                                :show="true"
                                type="chars"
                                tag="h2"
                                class="o-block__title | t-t2"
                            />

                            <anim-text
                                v-if="museum.desc"
                                :text="museum.desc"
                                :show="true"
                                type="html"
                                class="o-block__desc"
                            />

                            <btn
                                v-if="museum.website.length > 0"
                                tag="a"
                                target="_blank"
                                rel="noopener"
                                :label="museum.website[0].label"
                                :href="museum.website[0].url"
                                :title="museum.heading"
                                iconAfter="arrow-right"
                                class="o-block__btn"
                            />

                            <div class="o-block__bottom">
                                <h5 class="o-block__bottom-title | t-t5">Une Initiative du</h5>
                                <a
                                    :href="museum.website[0].url"
                                    :title="museum.website[0].label"
                                    class="o-block__bottom-link"
                                    v-cursor
                                >
                                    <icon icon="mbsl" />
                                </a>
                            </div>
                        </div>

                        <figure class="o-block__fig">
                            <img
                                src="~assets/img/layout/mbsl.jpg"
                                :alt="museum.heading"
                            />
                        </figure>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

import Icon from 'objects/Icon';
import Asset from 'objects/Asset';
import AnimText from 'objects/AnimText';
import Btn from 'components/Btn';
import Heading from 'layout/Heading';

import { mapState } from 'vuex'

export default {
    name: 'About',
    components: {
        Icon,
        Asset,
        AnimText,
        Btn,
        Heading,
    },
    props: {
        page: {},
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    data: () => ({
        headingPhotos: [],
        statsPhotos: [],
    }),
    computed: {
        ...mapState({
            totalPhotos: state => state.photos.totalPhotos
        }),
        museum() {

            if (!this.page.museum)
                return

            return this.page.museum.length > 0 ? this.page.museum[0] : false;
        },
        totalFunds() {
            return this.$store.getters['categories/getCategoryCount']('funds')
        },
    },
    created() {

        // Load heading photos
        this.$store.dispatch('photos/loadPhotos', 'limit: 3, orderBy: "RAND()"')
            .then(photos => {
                this.headingPhotos = photos
        })

        // Load stats photos
        this.$store.dispatch('photos/loadPhotos', 'limit: 4, offset: 20')
            .then(photos => {
                this.statsPhotos = photos
        })
    },
};

</script>

<style lang="scss">

// .p-about {}

.p-about__heading {

    @media #{md("sm")} {
        min-height: 100vh
    }
}

.p-about__content {
    margin-top: 10vh;

    > div {
        padding-top: .75em;
        padding-bottom: .75em;
        background-color: $color-beige;
        color: $color-dark;
    }
}


/*----------  Data  ----------*/

.o-stats {
    margin-right: calc(-1 * var(--grid-gutter-half));
    margin-left: calc(-1 * var(--grid-gutter-half));
}

.o-stats__photos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.o-stats__photo {
    position: absolute;
    will-change: transform;

    html.is-ios &, html.is-safari {
        transition: transform .75s ease-out;
    }

    &.-i1 {
        --parallax-y: -10;

        top: 0;
        left: 50%;
        width: calc(1/2 * 100%);

        @media #{md("xs", "max")} {
            --parallax-y: 0;
        }
    }

    &.-i2 {
        --parallax-y: -7;

        top: 25%;
        left: 0;
        width: calc(2/5 * 100%);

        @media #{md("xs", "max")} {
            --parallax-y: 0;
        }
    }

    &.-i3 {
        --parallax-y: 2;

        bottom: 25%;
        right: 0;
        width: calc(2/5 * 100%);

        @media #{md("xs", "max")} {
            --parallax-y: 0;
        }
    }

    &.-i4 {
        --parallax-y: -7;

        bottom: -10vh;
        right: 50%;
        width: calc(1/2 * 100%);

        @media #{md("xs", "max")} {
            --parallax-y: 0;
        }
    }

    @media #{md("xs")} {

        &.-i1 { width: calc(1/3 * 100%) }
        &.-i2 { width: calc(2/5 * 100%) }
        &.-i3 { width: calc(1/4 * 100%) }
        &.-i4 { width: calc(2/5 * 100%) }
    }

    @media #{md("sm")} {

        &.-i1 {
            left: calc(1/3 * 100%);
            width: calc(1/3 * 100%);
        }

        &.-i2 {
            right: -$header-size;
            left: auto;
            width: calc(1/6 * 100% + #{$header-size});
        }

        &.-i3 {
            left: 0;
        }

        &.-i4 {
            right: auto;
            left: 50%;
            width: calc(2/5 * 100%);
        }
    }

    @media #{md("lg")} {

        &.-i1 {
            left: calc(3/8 * 100%);
            width: calc(1/4 * 100%);
        }

        &.-i4 {
            width: calc(1/3 * 100%);
        }
    }
}

.o-stats__inner {
    left: calc(-1 * var(--grid-gutter));
    width: 100vw;
    display: block;
    overflow: hidden;

    @media #{md("sm")} {
        left: 0;
        width: calc(100vw - #{$header-size});
    }
}

.o-stats__item {
    display: flex;
    margin-top: 15vh;
    margin-bottom: 15vh;
    will-change: transform;

    html.is-ios &, html.is-safari {
        transition: transform .75s ease-out;
    }

    &.-i1 {
        --parallax-x: -20;
        --parallax-y: 5;

        @media #{md("xs", "max")} {
            --parallax-x: -10;
            --parallax-y: 0;
        }
    }

    &.-i2 {
        --parallax-x: -30;
        --parallax-y: 0;

        @media #{md("xs", "max")} {
            --parallax-x: 15;
        }
    }

    &.-i3 {
        --parallax-x: -40;
        --parallax-y: -5;

        @media #{md("xs", "max")} {
            --parallax-x: -20;
            --parallax-y: 0;
        }
    }
}

.o-stats__text {
    white-space: nowrap;

    &:nth-child(n+2) {
        margin-left: 1em;
    }
}



/*----------  Block  ----------*/

.o-block {
    --reveal-end: 1;
    --padding: 1.5em;

    margin-top: 10vh;
    color: $color-dark;
    background-color: $color-light;

    &.js-reveal {

        .-chars {
            @include at-chars-out;
        }

        .-html {
            @include at-html-out;
        }

        &.js-visible {

            .-chars {
                @include at-chars-in;
            }

            .-html {
                --anim-delay: .2s;
                @include at-html-in;
            }
        }
    }

    @media #{md("sm")} {
        --padding: 2.5em;
    }

    @media #{md("lg")} {
        --padding: 3em;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.o-block__content {
    padding-top: var(--padding);
    padding-right: var(--padding);
    padding-left: var(--padding);

    @media #{md("lg")} {
        width: 50%;
    }
}

.o-block__title {
    margin-bottom: .5em;
}

.o-block__btn {
    margin-top: 1em;
}

.o-block__fig {
    width: 50%;
    padding-top: .5em;

    img {
        @include img;
    }

    @media #{md("lg", "max")} {
        display: none;
    }

    @media #{md("lg")} {
        padding-right: var(--padding);
        padding-left: var(--padding);
    }
}

.o-block__bottom {
    display: flex;
    align-items: center;
    padding-top: var(--padding);

    > * {
        flex: 0 0 auto;
    }

    .o-icon {
        padding: 1em;
        color: $color-light;
        background-color: $color-red;
        border-top-right-radius: .4em;
        border-top-left-radius: .4em;
    }

    @media #{md("xs", "max")} {
        flex-direction: column;
    }
}

.o-block__bottom-title {
    margin-right: var(--grid-gutter);

    @media #{md("xs", "max")} {
        margin-top: 1em;
    }
    @media #{md("md")} {
        margin-right: var(--grid-gutter-half);
    }
}

.o-block__bottom-link {
    display: block;

    @media #{md("xs", "max")} {
        margin-top: 1em;
    }
}

</style>
